import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class PaymentwaysQuery extends DefaultFirebaseQuery {
  constructor (params = null) {
    super ({
      name: 'paymentways',
      path: 'paymentways',
    });
  }
}
