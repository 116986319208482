export function  formClass(formProperties){
    let defaultTitle = "";
    const componentTitle =  formProperties?.componentTitle || formProperties?.componentName

    if(formProperties?.action === "add"){
        defaultTitle = "Ajouter "; 
    }else if(formProperties?.action === "edit"){
        defaultTitle = "Modifier "; 
    }

    return {
        title : formProperties?.formFields?.formTitle || formProperties?.title || defaultTitle + " "+ componentTitle,
        btnText : formProperties?.btnText,
        fields : formProperties?.formFields,
        actionName : formProperties?.action,
        dataValue : formProperties?.data,
        renderComponent : formProperties?.formFields?.renderComponent
    };
}

export function fieldClass(fieldProperties){
    const title =  fieldProperties?.title || fieldProperties?.name;
    let fieldType = null;
    switch(fieldProperties?.type){
        case "money" : fieldType = "number"; break;
        default  : fieldType = fieldProperties?.type; break;
    }
    let defaultVal = fieldProperties?.dataValue || {};

    return {
        htmlElement : fieldProperties?.htmlElement || "input",
        type : fieldType,
        label : title,
        name : fieldProperties?.name,
        col: fieldProperties?.col || 12,
        id: fieldProperties?.name,
        handleChange : fieldProperties?.handleChange,
        subFormHandleChange : fieldProperties?.handleSubFormChange,
        icon : fieldProperties?.icon,
        options : fieldProperties?.options,
        fields : fieldProperties?.fields,
        maxValue : fieldProperties?.dataValue?.maxValue || fieldProperties?.maxValue,
        minValue : fieldProperties?.dataValue?.minValue || fieldProperties?.minValue,
        inputType : fieldProperties?.inputType,
        htmlElementType : fieldProperties?.htmlElementType,
        defaultValue : {
            errors : defaultVal?.errors,
            value : defaultVal?.value
        },
        multiple : fieldProperties?.multiple,
        editableDisable : fieldProperties?.editableDisable,
        disable: defaultVal?.disable,
        imgOptions : fieldProperties?.imgOptions,
        groupBy : fieldProperties?.groupBy
    };
}
