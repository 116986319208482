
const CardBuilder = props => {
  const items = props.information?.items || [];
  const SingleCardRender = props.information?.listSingleElementRender || null;

  return (
    <div className='row p-2'>
      {
        items.map(
          (item) =>  <SingleCardRender key={item.id} actions={props.information?.actions} openDialog={props.openDialog} information={item} />
        )
      }
    </div>
  );
};

export default CardBuilder;
