export default function DialogInfo (dialogProperties) {
    let loadDialogByProvidedProperties = null;
    switch(dialogProperties?.dialogContent?.actionName){
        case 'form' : 
                loadDialogByProvidedProperties = formDialogDefaultContent(dialogProperties?.dialogContent); 
            break;
        case 'view' : 
            loadDialogByProvidedProperties = singleDialogDefaultContent(dialogProperties?.dialogContent); 
        break;    
        case 'lock' : 
                loadDialogByProvidedProperties = lockDialogDefaultContent(dialogProperties?.dialogContent); 
            break;   
        case 'unlock' : 
                loadDialogByProvidedProperties = unlockDialogDefaultContent(dialogProperties?.dialogContent); 
            break;
        case 'valid' : 
                loadDialogByProvidedProperties = validDialogDefaultContent(dialogProperties?.dialogContent); 
                break;
        case 'performed' : 
                loadDialogByProvidedProperties = performedDialogDefaultContent(dialogProperties?.dialogContent);         
            break;
        case 'approved' : 
                loadDialogByProvidedProperties = approvedDialogDefaultContent(dialogProperties?.dialogContent);         
            break;     
        case 'close' : 
                loadDialogByProvidedProperties = closeDialogDefaultContent(dialogProperties?.dialogContent); 
            break;     
        case 'cancel' : 
                loadDialogByProvidedProperties = cancelDialogDefaultContent(dialogProperties?.dialogContent); 
            break;
        case 'delete' : 
                loadDialogByProvidedProperties = deleteDialogDefaultContent(dialogProperties?.dialogContent);   
            break;
        case 'accept' : 
                loadDialogByProvidedProperties = acceptDialogDefaultContent(dialogProperties?.dialogContent);   
            break;
        case 'decline' : 
                loadDialogByProvidedProperties = declineDialogDefaultContent(dialogProperties?.dialogContent);   
            break; 
        case 'logout' : 
                loadDialogByProvidedProperties = logoutDialogDefaultContent(dialogProperties?.dialogContent);   
            break;                           
        default : 
                loadDialogByProvidedProperties = personalDialogDefaultContent(dialogProperties?.dialogContent);   
            break;
    }

    return {
        dialogState: {
            isOpen: dialogProperties?.dialogState?.isOpen ?? false,
            openDialog : dialogProperties?.dialogState?.openDialog,
            closeDialog : dialogProperties?.dialogState?.closeDialog,
        },
        dialogContent: {
            ...loadDialogByProvidedProperties,
            size : dialogProperties?.dialogContent?.size || "sm" 
        }
    };
}


const singleDialogDefaultContent = params => {
    return {
        type:"singleView",
        typeContent : "otherContent",
        data : params?.data,
        renderComponent : params?.pDialogRender,

    }
}

const formDialogDefaultContent = params => {
    return {
        type : "form",
        typeContent : "otherContent",
        fields : {
            title : params?.title,
            action : params?.action,
            data : params?.fieldsData,
            formFields : params?.formFields,
            componentName : params?.componentTile ||params?.componentName,
        },
    }
} 

const personalDialogDefaultContent = params => {
    return {
        data : {
            title : params?.title || " "+ params?.componentName,
            action : params?.action,
            data : params?.data,
        },
        renderComponent : params?.pDialogRender,
        typeContent : "otherContent",
    }
} 


const lockDialogDefaultContent = params => {
    return buildTextDialogContent({
        title : "Verrouillage de",
        message :  "Souhaitez vous réellement verrouiller ceci ?",
        componentName : params?.componentTitle || params?.componentName,
        reference : params?.dataReference,
        executeAction : params.validAction,
        actionName:params?.actionName,
        data : params?.data
    });
}

const unlockDialogDefaultContent = params => {
    return buildTextDialogContent({
        title : "Déverrouillage de",
        message :  "Souhaitez vous réellement déverrouiller ceci ?",
        componentName : params?.componentTitle || params?.componentName,
        reference : params?.dataReference,
        executeAction : params.validAction,
        actionName:params?.actionName
    });
}

const validDialogDefaultContent = params => {
    return buildTextDialogContent({
        title : "Validation de",
        message :  "Souhaitez vous réellement valider cette action ?",
        componentName : params?.componentTitle || params?.componentName,
        reference : params?.dataReference,
        executeAction : params.validAction,
        actionName:params?.actionName,
        data : params?.data
    });
}

const approvedDialogDefaultContent = params => {
    return buildTextDialogContent({
        title : "Validation de",
        message :  "Souhaitez vous réellement valider cette action ?",
        componentName : params?.componentTitle || params?.componentName,
        reference : params?.dataReference,
        executeAction : params.validAction,
        actionName:params?.actionName,
        data : params?.data
    });
}

const performedDialogDefaultContent = params => {
    return buildTextDialogContent({
        title : "Exécution de",
        message :  "Souhaitez vous réellement exécuter cette action ?",
        componentName : params?.componentTitle || params?.componentName,
        reference : params?.dataReference,
        executeAction : params.validAction,
        actionName:params?.actionName,
        data : params?.data
    });
}
const closeDialogDefaultContent = params => {
    return buildTextDialogContent({
        title : "Fermerture de",
        message :  "Souhaitez vous réellement valider cette action ?",
        componentName : params?.componentTitle || params?.componentName,
        reference : params?.dataReference,
        executeAction : params.validAction,
        actionName:params?.actionName,
        data : params?.data
    });
}

const cancelDialogDefaultContent = params => {
    return buildTextDialogContent({
        title : "Annulation de",
        message :  "Souhaitez vous réellement annuler cette action ?",
        componentName : params?.componentTitle || params?.componentName,
        reference : params?.dataReference,
        executeAction : params.validAction,
        actionName:params?.actionName,
        data : params?.data
    });
}

const deleteDialogDefaultContent = params => {
    return buildTextDialogContent({
        title : "Suppression de",
        message :  "Souhaitez vous réellement supprimer cette action ?",
        componentName : params?.componentTitle || params?.componentName,
        reference : params?.dataReference,
        executeAction : params.validAction,
        actionName:params?.actionName,
    });
}

const acceptDialogDefaultContent = params => {
    return buildTextDialogContent({
        message : 'Souhaitez vous accepter cette action?',
        title : 'Accepter',
        executeAction : params.validAction,
        actionName:params?.actionName,
        reference : params?.dataReference,
    });
}

const declineDialogDefaultContent = params => {
    return buildTextDialogContent({
        message : 'Souhaitez vous refuser cette action?',
        title : 'Refus',
        executeAction : params.validAction,
        actionName:params?.actionName,
        reference : params?.dataReference,    
    });
}


const logoutDialogDefaultContent = params => {
    return buildTextDialogContent({
        message : 'Souhaitez vous réellement vous déconnecter?',
        title : 'Déconnexion',
        executeAction : params.validAction,
        actionName:params?.actionName
    });
}

function buildTextDialogContent(params){
    const title = `${params?.title} ${params?.componentName || ''} ${params?.reference ? '#'+params?.reference : ''}`;
    return {
        dialogTitle : title,
        dialogText : params?.message,
        type : "action" ,
        typeContent : "text",
        validAction : params.executeAction,
        actionName : params?.actionName,
        reference : params?.reference,
    }
}