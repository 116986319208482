import LoggedUserModel from '../models/login.model';
import DefaultController from './default.controller';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import RolesController from './roles.controller';

export default class UserController extends DefaultController {
  roleController = new RolesController ();

  constructor () {
    super ({
      path: 'users',
    });
  }

  loginErrors = [];

  login = async credentials => {
    this.checkLoginRules (credentials, [
      {title: 'username', rules: ['required']},
      {title: 'password', rules: ['required']},
    ]);

    if (this.loginErrors.length > 0) {
      return {
        data: null,
        errors: this.loginErrors,
        message: 'Entrées invalides',
        success: false,
      };
    }

    const auth = getAuth ();
    let data = null;

    let response = {data: data, errors: [], message: null, success: false};

    try {
      data = await signInWithEmailAndPassword (
        auth,
        credentials.username,
        credentials.password
      );
      response.success = true;
      response.message = 'Utilisateur authentifier avec succès';
      const authUser = new LoggedUserModel ();
      authUser.isLoggedIn = true;
      authUser.user = await this.format (data.user);
      response.data = authUser;
    } catch (exception) {
      response.success = false;
      response.message = 'Utilisateur introuvable dans la base de données';
      response.data = null;
      response.errors = [];
    }
    return response;
  };

  async format (user) {
    let userFound = await this.findByEmail (user.email);

    userFound.authorities = this.getAuthorities (userFound.roles);
    return userFound;
  }

  async getAuthorities (roles) {
    /*const rFound = roles.map (async role => {
      const roleFormat = await this.roleController.readOne (role);
      return roleFormat?.data;
    });*/
    //console.log('roles', await rFound)
    return '';
  }

  checkLoginRules (credentials, rules) {
    this.rulesChecker (this.loginErrors, credentials, rules);
  }

  register = async credentials => {
    const auth = getAuth ();
    createUserWithEmailAndPassword (
      auth,
      credentials.email,
      credentials.password
    );
    return await this.create (credentials);
  };

  async findByEmail (email) {
    const usersQuery = await this.readAll ();
    return usersQuery.data.find (user => user.email === email);
  }
}
