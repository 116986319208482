import {Fragment} from 'react';
import './frame-content.css';
import {Pagination} from '@mui/material';
import ListContentFrame from '../../../List';
import SingleContentFrame from '../../../Single';
import FormContentFrame from '../../../Form';

export default function FrameContent (props) {
  let GoodTypeFrame = null;
  let firstIndex = 0;
  let lastIndex = 0;

  switch (props.type) {
    case 'list':
      GoodTypeFrame = ListContentFrame;
      const length =
        props.frameInformation?.paginationOptions?.defaultEntriesLength;

      firstIndex =
        props.frameInformation?.paginationOptions?.selectedPage * length + 1;

      lastIndex =
        props.frameInformation?.paginationOptions?.selectedPage * length + length;

      break;
    case 'form':
      GoodTypeFrame = FormContentFrame;
      break;
    case 'single':
      GoodTypeFrame = SingleContentFrame;
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <GoodTypeFrame
        openDialog={props.openDialog}
        information={props.frameInformation}
        featureName={props.name}
      />
      {props.frameInformation?.paginationOptions?.totalPage > 0
        ? <div className="clearfix">
            <div className="hint-text">
              Affichage
              entrées de
              <b className="mx-1">{firstIndex}</b>
              {' '}
              à
              {' '}
              <b className="mx-1">{lastIndex}</b>

            </div>
            <div className="pagination">
              <Pagination
                count={props.frameInformation?.paginationOptions?.totalPage}
                color="primary"
                onChange={props.changePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        : null}

    </Fragment>
  );
}
