import moment from 'moment/moment';
import {capitalizeFirstLetter} from '../../../bootstrap/Helpers/utils';
import  Chip  from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import avatar from "./../../../assets/images/avatar.png";
import image from "./../../../assets/images/img.jpg";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import buildHeader from './../../base-component-class/build-header';
import appParams from './../../../configs/app-params';


const SingleContentFrame = props => {
  return (
    <div className="container personal-frame bg-light">
      <div className="row">
        <div className="col-md-12 bg-primary p-1 justify-content-center align-items-center">
          <h4 className="text-white text-center fw-bold fst-italic">
            DETAIL {props.data?.title?.toUpperCase()} # {props.data?.reference}
          </h4> 
        </div>
        <DataFetching data={props} />
      </div>
    </div>
  );
};

const DataFetching = props => {
  const data = props.data?.data ||  null;
  const columns = data?.columns || [];
  
  const item = data?.rows || {};
  
  return  <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableBody>
                  {
                    columns?.map(
                        (column,index) => {
                          const header = buildHeader(column); 
                          return <TableRow
                                    key={header?.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell component="th" scope="row">
                                          {capitalizeFirstLetter(header?.title)}
                                        </TableCell>
                                        <TableCell align="left">
                                          {
                                            <SingleRenderRow columnRow={header} itemValue={item} index={index}/>
                                          }
                                        </TableCell>
                                </TableRow>      
                        })
                  }   
                  
                </TableBody> 
            </Table>
          </TableContainer>;
};

const SingleRenderRow = props => {
  const column = props?.columnRow;
  const item = props?.itemValue; 
  let RenderDisplayTd = null;
  switch(column?.type.toLowerCase()){
      case 'objectfetcher' :
              let text = ""; 
              RenderDisplayTd = <span>
                  {
                       
                  column?.fields?.forEach(
                      field => {
                          text = text+" "+item?.[column?.name]?.[field];
                      }
                  )}
                  {text}
              </span>; 
          break;
      case 'date' :
        RenderDisplayTd = <span>{
            item?.[column?.name] ?
            moment(item?.[column?.name]).format("DD/MM/YYYY") : null}</span>; 
        break; 
      case 'money' :
          RenderDisplayTd =   <span>
                                  {
                                      Intl.NumberFormat(column?.devise || appParams.language,{ style: 'currency',
                                          currency: column?.currency || appParams.currency,
                                          maximumSignificantDigits: 3,
                                              }).format(item?.[column?.name])    
                                  }
                              </span>; 
        break;     
    case 'datetime' :
            RenderDisplayTd = <span>
                                    {
                                        item?.[column?.name] ? 
                                        moment(item?.[column?.name]).format("DD/MM/YYYY hh:mm:ss"): null
                                    }
                              </span>; 
        break;         
    case 'checkbox' : 
            const j = item?.[column?.name] ? 1 : 0;
            RenderDisplayTd =  
                <Chip 
                    sx={
                        { 
                            p:1,
                            bgcolor : `${column?.options[j]?.badgeColor}`,
                            color : `${column?.options[j]?.textColor ?? 'white'}` 
                        }
                    }
                    label={`${column?.options[j]?.text}`} 
                    variant="outlined" 
                />
        break;
     case 'state-multiple' : 
            RenderDisplayTd =  
                <Chip 
                    sx={
                        { 
                            p:1,
                            bgcolor : `${column?.options[item?.[column?.name]]?.badgeColor}`,
                            color : `${column?.options[item?.[column?.name]]?.textColor ?? 'white'}` 
                        }
                    }
                    label={`${column?.options[item?.[column?.name]]?.text}`} 
                    variant="outlined" 
                />
        break;        
      
      case 'user-avatar' :                                            
                  const imgPath = item?.[column?.name] ?? avatar;
                  RenderDisplayTd = <Avatar
                                      alt="avatar"
                                      src={imgPath}
                                      sx={{ width: 50, height: 50 }}
                                  />
                  
              break;
      case 'image' : 
                  const img = item?.[column?.name] ?? image; 
                  RenderDisplayTd = <img 
                          src={img} 
                          className="img img-thumbnail"  
                          alt="myImg"
                          style={{ 
                              height : '50px',
                              width : '50px',
                           }}
                      />        
              break;            
      default : 
              RenderDisplayTd = <span>{item?.[column?.name]}</span> ;
          break;
  }

  return <span className="p-2" key={item?.id + column?.title + props.index}>
              {RenderDisplayTd}  
          </span>
}

export default SingleContentFrame;
