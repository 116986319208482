import React, { Fragment } from 'react';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SingleField } from '..';

export default class EditableLabel extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            editableMode : false
        }

        this.enableEditable = this.enableEditable.bind(this);
        this.disableEditable = this.disableEditable.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    enableEditable(e){
        this.setState({editableMode : true});
    }

    disableEditable(e){
        this.setState({editableMode : false});
    }

    handleChange = (event,value = null, name = null) => {
        this.props.fieldInformation?.handleChange(event,value,name)
    } 

    async setValue(value){
        await this.setState({value: value})
    }

    async componentDidUpdate(prevProps,prevState){
        if(this.props.fieldInformation !== prevProps.fieldInformation){
            await this.setValue(this.props.fieldInformation?.defaultValue?.value);
        }
    }
    
    render () {
        const labelInformation = this.props.fieldInformation;
        
        return  <div className={`d-flex form-group col-${labelInformation?.col} my-2`}>
                    {
                        !this.state.editableMode ? 
                        <Fragment>
                            <span className="my-auto">{labelInformation?.label} : </span>
                            <span className="mx-1 my-auto"> {this.state.value || ""} </span>
                            {
                                !labelInformation?.editableDisable ? <span className="mx-1">
                                <Button onClick={this.enableEditable} color="success" size="small" variant='outlined'  endIcon={<FontAwesomeIcon icon="pen"/>}>
                                    Editer
                                </Button>
                            </span> : null

                            }      
                        </Fragment> : 
                        <Fragment>
                            <div className="my-auto">
                                <SingleField
                                    options = {this.props.fieldInformation?.options || []}
                                    value = {this.state.value || ""} 
                                    field={{ 
                                        ...labelInformation,
                                        handleChange : this.handleChange,
                                        htmlElement : labelInformation?.htmlElementType,
                                        type : labelInformation?.inputType,
                                        title : labelInformation?.label,
                                    }}
                                />
                            </div>
                            <span className="mx-2 my-auto">
                                <Button onClick={this.disableEditable} color="error" size="small" variant='outlined'  endIcon={<FontAwesomeIcon icon="times"/>}>
                                    Fermer
                                </Button>
                            </span>
                        </Fragment>
                    }
                  
                </div>;
    }
}
