import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const banksConfiguration = {
  name: 'banks',
  title: 'Banques',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom',
    },
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'lg',
    },
  },
};

export default BaseConfiguration (banksConfiguration);
