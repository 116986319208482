import { Fragment } from 'react';
import { capitalizeFirstLetter, checkConditions } from './../../../bootstrap/Helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

import avatar from "./../../../assets/images/avatar.png";
import image from "./../../../assets/images/img.jpg";
import moment from 'moment';
import appParams from '../../../configs/app-params';
//import { useSelector } from 'react-redux';

export default function TableBuilder (props) {
    const headers = props.information?.dataColumns; 
    const actions = props.information?.actions?.actions || props.information?.actions; 
    return (
        <table className="table table-striped table-hover table-table-responsive" width="100%">
            <THead headers={headers} actionsLength ={actions?.length || 0} />
            <TBody featureName={props.featureName} actions={actions || []} data={props.information?.items ?? []} headers={headers} openDialog={props.openDialog}/>
            {/*<TFoot headers={headers} />*/}
        </table>
    );
}

const THead = props => {
  return (
    <thead>
      <tr>
       <th scope="id">#</th>
       {
        props.headers?.map(
            header => <th scope={header.title} key={header.title}>
                {capitalizeFirstLetter(header.title)}
            </th>
        )
       }
       {
        props.actionsLength > 0 ?  <th scope="action">ACTIONS</th> : null
       }      
      </tr>
    </thead>
  );
}

const TBody = props => {

    return (
    <tbody>
        {
            props.data?.length > 0 ?  props.data.map(
                (item,index) => {
                    return <TableRowsBuilder 
                                    dataId={item.id} 
                                    index={index +1} 
                                    key={item.id} 
                                    actionsProperties={props.actions} 
                                    providedItem={item} 
                                    dataColumns={props.headers}
                                    openDialog={props.openDialog}
                                    featureName={props.featureName}
                            />
                }
            ) : 
            <tr>
                <td className="text-center" colSpan={props.headers.length + 2}> Aucune ligne trouvée</td>
            </tr>
        }
        
      
    </tbody>
  );
}


const TableRowsBuilder = props => {
    const item = props.providedItem;
    const columns = props.dataColumns;
    const actionsProperties = {
        actions : props.actionsProperties,
        dataId: props.dataId,
        data : item,
        featureName : props.featureName
    };
    return  <tr>
                <td>{props.index ?? 1}</td>
                {
                columns?.map(
                    (column,index) => {
                        let RenderDisplayTd = null;

                        switch(column?.type?.toLowerCase()){
                            case 'objectfetcher' :
                                    let text = ""; 
                                    RenderDisplayTd = <span>
                                        {
                                             
                                        column?.fields?.forEach(
                                            field => {
                                                text = text+" "+item?.[column?.name]?.[field];
                                            }
                                        )}
                                        {text}
                                    </span>; 
                                break;
                            case 'date' :
                                    RenderDisplayTd = <span>{
                                        item?.[column?.name] ?
                                        moment(item?.[column?.name]).format("DD/MM/YYYY") : null}</span>; 
                                break; 
                            case 'money' :
                                    RenderDisplayTd =   <span>
                                                            {
                                                                Intl.NumberFormat(column?.devise || appParams.language,{ style: 'currency',
                                                                    currency: column?.currency || appParams.currency,
                                                                    
                                                                        }).format(item?.[column?.name])    
                                                            }
                                                        </span>; 
                                break;     
                            case 'datetime' :
                                    RenderDisplayTd = <span>
                                                            {
                                                                item?.[column?.name] ? 
                                                                moment(item?.[column?.name]).format("DD/MM/YYYY hh:mm:ss"): null
                                                            }
                                                      </span>; 
                                break;         
                            case 'checkbox' : 
                                    const i = item?.[column?.name] ? 1 : 0;
                                    RenderDisplayTd =  
                                        <Chip 
                                            sx={
                                                { 
                                                    p:1,
                                                    bgcolor : `${column?.options[i]?.badgeColor}`,
                                                    color : `${column?.options[i]?.textColor ?? 'white'}` 
                                                }
                                            }
                                            label={`${column?.options[i]?.text}`} 
                                            variant="outlined" 
                                        />
                                break;
                             case 'state-multiple' : 
                                    RenderDisplayTd =  
                                        <Chip 
                                            sx={
                                                { 
                                                    p:1,
                                                    bgcolor : `${column?.options[item?.[column?.name]]?.badgeColor}`,
                                                    color : `${column?.options[item?.[column?.name]]?.textColor ?? 'white'}` 
                                                }
                                            }
                                            label={`${column?.options[item?.[column?.name]]?.text}`} 
                                            variant="outlined" 
                                        />
                                break;    
                            case 'user-avatar' :                                            
                                        const imgPath = item?.[column?.name] ?? avatar;
                                        RenderDisplayTd = <Avatar
                                                            alt="avatar"
                                                            src={imgPath}
                                                            sx={{ width: 30, height: 30 }}
                                                        />
                                        
                                    break;
                            case 'image' : 
                                        const img = item?.[column?.name] ?? image; 
                                        RenderDisplayTd = <img 
                                                src={img} 
                                                className="img img-thumbnail"  
                                                alt="myImg"
                                                style={{ 
                                                    height : '50px',
                                                    width : '50px',
                                                 }}
                                            />        
                                    break;
                            case 'number':    
                                        let val = 0; 
                                                
                                        if(item?.[column?.name] && item?.[column?.name] !== "NaN"){
                                           val =  item?.[column?.name];
                                        }
                                        RenderDisplayTd = <span>
                                            {val?.toLocaleString("fr-FR")}
                                        </span>
                                    break;           
                            default : 
                                    RenderDisplayTd = <span>{item?.[column?.name]?.toLocaleString("fr-FR")}</span> ;
                                break;
                        }

                        return <td className="p-2" key={item?.id + column?.title + index}>
                                    {RenderDisplayTd}  
                                </td>
                    }
                )
              }
            {
                actionsProperties?.actions?.length > 0 ? <td>
                {BuildTableAction(actionsProperties,props.openDialog)}
            </td>   : null
            }  
           
            </tr>
}


/*const TFoot = props => {
  return (
    <tfoot>
      <tr>
       <th scope="id">#</th>
       {
        props.headers?.map(
            header => <th scope={header.title} key={header.title}>
                {capitalizeFirstLetter(header.title)}
            </th>
        )
       }
       <th scope="action">ACTIONS</th>
      </tr>
    </tfoot>
  );
}*/


export function BuildTableAction(actionsData,openDialog){
   // const currentUser = useSelector(state => state.auth);

    return <Fragment>
            {
            actionsData?.actions?.map(
                action => {
                    /*if(!currentUser?.user?.authorities?.includes(actionsData?.featureName+":"+action?.name)){
                        return null;
                    }*/

                    const actionClass = "btn btn-sm my-sm-1 btn-"+action.color;
                    let displayBtn = true;
                    if(action?.elementTest && action?.elementTest !== ""){
                        displayBtn = actionsData?.data?.[action?.elementTest] === action.value;
                        if(action?.operator && action?.operator === "different"){
                            displayBtn = actionsData?.data?.[action?.elementTest] !== action.value;
                        }
                    }

                    if(action?.renderConditions && action?.renderConditions.length > 0){
                        let tests = [];

                        action?.renderConditions.forEach(
                            condition => {
                                let test = actionsData?.data?.[condition?.field] === condition?.value;
                                
                                if(condition?.operation === "different"){
                                    test = actionsData?.data?.[condition?.field] !== condition?.value;
                                }
                                tests.push(test);
                            }
                        );


                        
                       tests.forEach(
                            (test,index) => {
                                let operation = action?.renderConditions[index]?.nextConditionConnector;
                                

                                if(index > 0 && index < tests.length - 1){
                                    if(operation){
                                        if(checkConditions(tests[index-1],operation,tests[index])) {
                                            displayBtn = false;
                                        }             
                                    }
                                }else{
                                    if(test){
                                        displayBtn = false;
                                    }
                                }
                            }
                        )
                        
                       
                    }
                    return  <Fragment key={action?.name}>
                                {
                                    displayBtn ?  <button 
                                    className={actionClass + " mx-1 p-1 px-2 "}
                                    onClick={(e) => {openDialog(e,action?.name,actionsData.dataId)}}
                                    name={action?.name}
                                    id={actionsData.dataId}
                                    title={action?.title}
                                >
                                    <FontAwesomeIcon icon={action?.icon}/>
                                </button> : null
                                }
                               
                            </Fragment>
                }
            )
        }
    </Fragment>
}