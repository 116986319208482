import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import avatarImg from "../../../../assets/images/profile.jpg";
import imgPiker from "../../../../assets/images/img.jpg";
import { capitalizeFirstLetter, getBase64 } from '../../../../bootstrap/Helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-phone-input-2/lib/material.css'
import MuiPhoneNumber from "material-ui-phone-number";
import  IconButton  from '@mui/material/IconButton';

export default function DisableInputBuilder(props){
    let inputInformation = props?.fieldInformation;

    let Input = null;

    switch( inputInformation?.type?.toLowerCase()){
        case 'user-avatar' : 
                Input = AvatarPicker;
            break;
       case 'date' : 
            Input = InputTypeDate;
        break;
        case 'checkbox' : 
                Input = InputTypeCheckBox;
            break;
        case 'phone' : 
                Input = InputTypePhone;
            break;
        case 'image' : 
                Input = ImagePicker;
            break;             
        default : 
                 Input = InputTypeText;
                break;
    }

    return  <BaseInput render={Input} fieldInformation={inputInformation}/>
}

class BaseInput extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            value : null,
            disable : true,
        }
        this.setValue = this.setValue.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    async setValue(value){
       await this.setState({value:value});
    }

    async componentDidUpdate(prevProps,prevState){
        if(this.props.fieldInformation !== prevProps.fieldInformation){
            if(this.props.fieldInformation.editableDisable){
                await this.setState({canUpdate : false})
            }else{
                await this.setState({canUpdate : true})
            }
            
            await this.setValue(this.props.fieldInformation?.defaultValue?.value);
        }
    }

    makeInputEditable = e => {
        this.setState({disable : !this.state.disable})
    }

    changeValue = async (e,value = null,name = null) => {
       await this.props?.fieldInformation?.handleChange(e,value,name)
    }

    render(){
        let inputInformation = this.props?.fieldInformation;
        const Render = this.props.render;
        return <Render setToEditable={this.makeInputEditable} updatable={this.state.canUpdate}  disable={this.state.disable} value={this.props.fieldInformation?.defaultValue?.value} changeValue={this.changeValue} fieldInformation={inputInformation}/>
    }
}

export function InputTypeText(props) {
        let inputInformation = props?.fieldInformation;
        
        return  <div className={`form-group col-${inputInformation?.col} d-flex my-2`}>
                    <TextField
                        id={inputInformation?.id}
                        label={capitalizeFirstLetter(inputInformation?.label)}
                        name={inputInformation?.name}
                        type={inputInformation?.type}
                        fullWidth
                        error={inputInformation.errors}
                        onChange={props.changeValue}
                        value={props.value ||  ""}
                        helperText={inputInformation?.errors}
                        InputProps={{ inputProps: { min: inputInformation?.minValue, max: inputInformation?.maxValue } }}
                        disabled = {props.disable}
                        size={inputInformation.size || "medium"}
                    />
                    {
                        props.updatable ?  <div className='my-auto'>
                                                {
                                                    props.disable? 
                                                    <IconButton 
                                                            onClick={props.setToEditable} 
                                                            size="small" 
                                                            color="#fff"
                                                            sx={{ bgcolor : 'green', p :1, mt:0, mx: 1 }} 
                                                            aria-label="Editer">
                                                        <FontAwesomeIcon color="#fff" icon="pen"/>
                                                    </IconButton> 
                                                    : <IconButton 
                                                        onClick={props.setToEditable} 
                                                        size="small" 
                                                        color="#fff"
                                                        sx={{ bgcolor : 'red', p :1, mt:0, mx: 1 }} 
                                                        aria-label="Fermer">
                                                    <FontAwesomeIcon color="#fff" icon="times"/>
                                                </IconButton>
                                                }
                                                
                                            </div>   : null
                    }
                   
                    
                </div> 
}


export function InputTypeCheckBox(props){
        let inputInformation = props?.fieldInformation;
        const changeValue = async e => {
            await props.changeValue(e,!props.value)
        }
        return <div className={`form-group col-${inputInformation?.col} my-2`}>
                    <FormGroup>
                        <FormControlLabel 
                            id={inputInformation?.id}
                            control={
                                <Checkbox 
                                    name={inputInformation?.name}
                                    checked={props.value || false}
                                    onChange={changeValue}
                                />
                            } 
                            label={capitalizeFirstLetter(inputInformation?.label)}
                            disabled = {props.disable}
                        />
                    </FormGroup>
                </div>
}


export function AvatarPicker(props){
        let inputInformation = props?.fieldInformation;
       

        const changeImage = async (e) => {
            const file = e.target.files[0];
            const base64Img = await getBase64(file);
            props.changeValue(e,base64Img,inputInformation?.name);
        } 
    
        const removeImg = e => {
            props.changeValue(e,"null-img",inputInformation?.name);
        }
        return <div className={`form-group col-${inputInformation?.col} my-2`}>
                    <img src={props.value || avatarImg} alt="user_img" className="img img-thumbnail mx-1 mb-1" width='80px' height='80px'/>

                    <div className="d-flex">
                        <label htmlFor={inputInformation?.id} className="mx-1 mb-0 ">
                            <span className="btn btn-sm btn-success">Changer photo</span>
                        </label>
                        {
                            props.value ? <button type="button" onClick={removeImg} className='btn btn-sm btn-danger'>
                            <FontAwesomeIcon icon="trash" />    
                        </button> : null
                        }
                           
                    </div>
                    <input
                        id={inputInformation?.id} 
                        type="file" 
                        className="d-none" 
                        name={inputInformation?.name} 
                        onChange={changeImage}
                        accept="image/png, image/jpeg, image/jpg"
                    />
                </div>
}

export function ImagePicker(props){
    let inputInformation = props?.fieldInformation;
   
    const changeImage = async (e) => {
        const file = e.target.files[0];
        const base64Img = await getBase64(file);
        props.changeValue(e,base64Img,inputInformation?.name);
    } 

    const removeImg = e => {
        props.changeValue(e,"null-img",inputInformation?.name);
    }
    return <div className={`form-group col-${inputInformation?.col} my-2`}>
                <img src={props.value || imgPiker} alt="user_img" className="img img-thumbnail mx-1 mb-1" width='80px' height='80px'/>

                <div className="d-flex">
                    <label htmlFor={inputInformation?.id} className="mx-1 mb-0 ">
                        <span className="btn btn-sm btn-success">Changer photo</span>
                    </label>
                    {
                        props.value ? <button type="button" onClick={removeImg} className='btn btn-sm btn-danger'>
                        <FontAwesomeIcon icon="trash" />    
                    </button> : null
                    }
                       
                </div>
                <input
                    id={inputInformation?.id} 
                    type="file" 
                    className="d-none" 
                    name={inputInformation?.name} 
                    onChange={changeImage}
                    accept="image/png, image/jpeg, image/jpg"
                />
            </div>
}

export function InputTypePhone(props){
    let inputInformation = props?.fieldInformation;

    const handleChange = async value => {
        props.changeValue(null,value,inputInformation?.name);
    }
   
    return <div className={`form-group col-${inputInformation?.col} my-2`}>
                <MuiPhoneNumber
                    id={inputInformation?.id}
                    fullWidth
                    label={capitalizeFirstLetter(inputInformation?.label)}
                    variant="outlined"
                    defaultCountry={"ci"}
                    onChange={handleChange}
                    value={props.value}
                    enableLongNumbers
                />
            </div>
}

export function InputTypeDate(props){
    let inputInformation = props?.fieldInformation;

    const [focus, setFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    const changeDateValue = e => {
    if(e.target.value){
        setHasValue(true);
    }else{
        setHasValue(false);
    }
        props.changeValue(e);
    }
    return   <TextField
                label={capitalizeFirstLetter(inputInformation?.label)}
                id={inputInformation?.id}
                value={props?.value}
                fullWidth
                onChange={changeDateValue}
                name={inputInformation?.name}
                max={inputInformation?.maxValue || null}
                onFocus={onFocus}
                onBlur={onBlur}
                type={hasValue || focus ? "date" : "text"}
                min={inputInformation?.minValue}
            />
      
}

