import TableBuilder from '../Table';
import CardBuilder from '../Card';

const ListContentFrame = props => {
  const CustomizeListRender = props.information?.customizeListRender; 
  const elementType = props.information?.fetchElement;
  return (
    <div className='overflow-x-hidden'>
        {
            CustomizeListRender ? 
            <CustomizeListRender {...props}/> : 
            elementType === 'card' ?  <CardBuilder {...props}/> : 
            <TableBuilder {...props}/>
        }
    </div>
  );
};

export default ListContentFrame;
