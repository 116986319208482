/**
 * AIR SITE REACT TOOLS
 * Use to have global states for specify variable
 */
import {configureStore} from '@reduxjs/toolkit';
import reducers from './reducers';

const stores = configureStore ({
  reducer: reducers,
});

export default stores;
