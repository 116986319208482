import {Fragment} from 'react';
import FrameTitle from './_layouts/title/index';
import FrameSearchZone from './_layouts/search';
import FrameContent from './_layouts/content/index';
import { formConfiguration, listConfiguration, singleConfiguration } from '../../base-component-class/config.class';

export default function FrameBuilder (props) {

  const actions = props.configurations?.frameActions?.tableActions.find(
    item => item.frameType === props.configurations?.typeFrame 
  );
  let content  = buildFrameRender(props.configurations);

  
  const changePageLength = (e) => {
    props.configurations.handleSearchOption(e);
  }
  const changePage = (e,value) => {
    props.configurations.handleSearchOption(e,value - 1,"page");
  } 

  const onFilterData = (e,value = null,name=null) => {
    props.configurations.handleSearchOption(e,value,name);
  }
  
  return (
    <Fragment>
        <FrameTitle
          name={props.configurations?.name}
          title={props.configurations?.title}
          openDialog={props.openDialog}
          actions={actions?.actions}
        />
         {
          props.configurations?.typeFrame === 'list' 
            ? 
            <FrameSearchZone
                name={props.configurations?.name}
                defaultPageLength={content.paginationOptions?.defaultEntriesLength} 
                changePage = {changePageLength}
                paginationOptions={content?.paginationOptions} 
                searchOptions={content?.filterOptions}
                handleFilterFieldChange={onFilterData}
                values={props.configurations.searchOptions}
                options={props.configurations.options}
              /> 
            : 
            null
        }
        {
          props.configurations.componentBeforeRenderList ?  
            props.configurations.componentBeforeRenderList(props.configurations?.fetchData) : null
        }
        <FrameContent
          name={props.configurations?.name} 
          selectedSize={props.configurations?.searchOptions?.size}
          fetchData = {props.configurations?.fetchData} 
          frameInformation={content}
          changePage={changePage}
          type={props.configurations?.typeFrame}
          openDialog={props.openDialog}
          searchValues={props.configurations?.searchOptions}
        />
     </Fragment> 
  );
}


function buildFrameRender(contentConfigs){
  let frameContent = null;
  switch(contentConfigs?.typeFrame){
    case 'list' : frameContent = listConfiguration(contentConfigs); break;
    case 'single' : frameContent = singleConfiguration(contentConfigs); break;
    case 'form' : frameContent = formConfiguration(contentConfigs); break;
    default : break;
  }

  return frameContent;
}