import DefaultFirebaseQuery from './../../vendor/bases/firebase.query';

export default class BanksQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'banks',
      path: 'banks',
    });
  }
}
