import {combineReducers} from 'redux';
import userReducer from './user.reducer';

/**
 * Use to store a variable to the global state
 */
const reducers = combineReducers ({
  auth: userReducer,
});

export default reducers;
