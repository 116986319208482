import {Alert, Snackbar} from '@mui/material';
import {Fragment, useState} from 'react';

export default function BaseTemplate (props) {


  const [snackOpenState, setSnackOpenState] = useState (props.snackBarInformation?.isOpen);

  const handleSnackClose = () => {
    setSnackOpenState (false);
  };
 
  return (
    <Fragment>
      {props.frameConfigs.render ({...props})}
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={props.snackBarInformation?.isOpen || snackOpenState}
        onClose={handleSnackClose}
        autoHideDuration={3000}
        key={'snack'}
      >
        <Alert onClose={handleSnackClose} severity={props?.snackBarInformation?.color || 'info'} sx={{width: '100%'}}>
          {props?.snackBarInformation?.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
