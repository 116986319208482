import apiConfig from "../../configs/config";

/**
 * Class for call api's
 */
export default class Query{
    constructor(path){
      this.path = path;
    }  
    
    async createMethod (path,payLoad) {
        let data = null;
        if (payLoad) {
          try{
            data = {
              success : true,
              data : await this.getGoodServerRoute(path).post (path, payLoad),
              message : null,
              errors : []
            }
           
          }catch(exception){
           data = {
            success : false,
            data : null,
            message : exception?.response?.data?.message,
            errors : exception?.response?.data,
          }
          }
        }
        return data;
    }
    
    async getMethod (path,options = null) {
        let data = null;
        if (path) {
          try{
            data = {
              success : true,
              message : null,
              errors : [],
              data : await this.getGoodServerRoute(path).get (path,{params : options || null})
            }

          }catch(exception){
            data = {
              success : false,
              data : null,
              message : exception?.response?.data?.message,
              errors : null,
            }
          }
        }
        return data;
    }

    async updateMethod (path, payLoad) {
        let data =  null;
        if (path && payLoad)  {
          try{
            data = {
              success : true,
              message : null,
              errors : [],
              data : await this.getGoodServerRoute(path).put (path, payLoad)
            }

          }catch(exception){
            data = {
              success : false,
              data : null,
              message : exception?.response?.data?.message,
              errors : exception?.response?.data,
            }
          }
        }
        return data;
    }
    
    async deleteMethod (path) {
        let data =  null;
        if (path)  {
          try{
            data = {
              success : true,
              message : null,
              errors : [],
              data : await this.getGoodServerRoute(path).delete (path)
            }

          }catch(exception){
            data = {
              success : false,
              data : null,
              message : exception?.response?.data?.message,
              errors : null,
            }
          }
        }
        return data;
    }

    
    getGoodServerRoute(path){
        const basePath = path.replace(apiConfig.api,""); 
          
        const inclusionTest = apiConfig?.apiPublicRoutes?.includes(basePath?.startsWith('/') ? basePath?.replace("/", '') : basePath );
        if(!inclusionTest){
          const user = JSON.parse (localStorage.getItem ('user'));
          let token = null; 
          if (user) {
            token = user._token ?? null;
           // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          }
        }else{
         // axios.defaults.headers.common['Authorization'] = null;
        }
        //return axios;
        return {}
    }
}