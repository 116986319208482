import config from './config.json';
import mode from './envFile';

const apiConfig = {
  api: mode === 'prod'
    ? 'https://pikado.gestikenzo.com/api'
    : `${config.API_URL}:${config.API_PORT}`,
  appName: config.APP_NAME,
  apiPublicRoutes: ['user/login'],
};

export default apiConfig;
