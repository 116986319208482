import React from 'react';
import Typography from '@mui/material/Typography';

export function Copyright (props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <a
        className="text-muted"
        target="_blank"
        color="inherit"
        rel="noreferrer"
        href="https://p-air23.cloud/"
      >
        A.I.R
      </a>{' '}
      {new Date ().getFullYear ()}
      {'.'}
    </Typography>
  );
}
