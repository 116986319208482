import avatarImg from './../../../../../../../assets/images/avatar.png';

export default function SideUser(props){
    const user = props.user;
    return  <div className="user-block d-flex p-0 mt-2 mb-2">
                <div className="user-pic mx-0">
                    <img
                     className="img-responsive  profile-img"
                        src={user?.image || avatarImg}
                        alt="user profile img"
                    />
                </div>
                <div className="user-info p-2 mt-2">
                    <span className="user-name">
                        {user?.lastName}
                        <strong className="mx-1">{user?.firstName}</strong>
                    </span>
                    <span className="user-role">{user?.roleName}</span>
                </div>
            </div>
}