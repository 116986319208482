import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class ResourcesQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'resources',
      path: 'resources',
    });
  }
}
