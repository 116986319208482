import { Autocomplete, TextField } from "@mui/material";

export default function SearchAutoCompleteZone(props) {
    return (
      <Autocomplete
        id={props.data.name}
        options={props.data.options}
        onChange={(e,value) => {
          e.preventDefault();
          props.changeSearchValue(e,value?.value,props.data.name)
        }}
        size="small"
        sx={{ width:200,mr:1 }}
        renderInput={(params) => <TextField {...params} label={`${props.data.title}`} />}
        value={props.data.options.find(item => item.value === props.data.value) || null}
      />
    );
  } 
  