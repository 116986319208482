import { capitalizeFirstLetter } from '../../../../../bootstrap/Helpers/utils';
import { Link } from 'react-router-dom';
import './breadcrumb.css';

export default function CustomizedBreadcrumbs (props) {
  const crumbs = props.options ?? []; 
  return (
    <div className="breadcrumb flat">
        {
            crumbs?.map(
              (crumb,index) => {
                  return <Link
                            key={crumb.title.toLowerCase()} 
                            className={index === crumbs.length-1 ? "active" : ""} 
                            to={"/"+crumb.link ?? crumb.title.toLowerCase()}
                          >
                            {capitalizeFirstLetter(crumb.title)}
                          </Link>
                        }
              
            )
        }
      </div>
  );
}
