import Service from '../../vendor/bases/service';

export default class ResourcesServices extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'resources',
    });
  }
}
