import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const paymentWaysConfiguration = {
  name: 'paymentways',
  title: 'Moyen(s) de paiement',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom',
      col: '6',
    },
    {
      name: 'recipient',
      title: 'Bénéficiaire',
      htmlElement: 'select',
      type: 'objectFetcher',
      col: '6',
      options: [],
      dataServiceSource: 'recipients',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'recipients',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (paymentWaysConfiguration);
