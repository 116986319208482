import BaseBackComponent from '../base-back-component';

export default class ClientsComponent extends BaseBackComponent {
  name = 'Clients';

  options = [];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
  }

  otherSingleDataInit (data) {
    return data;
  }
}
