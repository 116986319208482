import { Alert, Snackbar } from '@mui/material';


export default function SnackbarBuilder(props){
    const snackProperties = props?.snackProperties;
    return <Snackbar
                anchorOrigin={{  vertical:'top',  horizontal:'right' }}
                open={snackProperties?.isOpen}
                onClose={snackProperties?.onClose}
                autoHideDuration={3000}
                key={"snack"}
                sx={{ p:2 }}
            >
                <Alert 
                    onClose={snackProperties?.onClose} 
                    severity={snackProperties?.color || 'info'} 
                    sx={{width: '100%'}}
                >
                    {snackProperties?.message}
                </Alert>
            </Snackbar>
}