import React, {Fragment} from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {fieldClass, formClass} from '../../base-component-class/form.class';
import InputBuilder from './Input';
import SelectBuilder from './Select';
import TextareaBuilder from './Textarea';
import DynamicForm, { DynamicUnChangeForm } from './DynamicForm/index';
import EditableLabel from './EditableLabel';
import DisableInputBuilder from './DisableInput';

const FormContentFrame = props => {
  return <FormBuilder {...props} />;
};

export function FormBuilder(props){
    const formInformation = formClass (props.formInformation);

    const FormRenderComponent = formInformation.renderComponent;

    return (
      <Fragment>
        {
          FormRenderComponent ? 
          <FormRenderComponent {...formInformation} /> :  <form onSubmit={formInformation?.fields?.onSubmitForm} method="POST">
          <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                  {formInformation?.title}
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <div className="row mt-2">
           <FormFields 
                handleChange={formInformation?.fields?.handleFormField} 
                handleSubFormChange={formInformation?.fields?.handleSubFormField} 
                fields={formInformation?.fields?.fields}
                value = {formInformation?.dataValue}
                options={formInformation?.fields?.loadFieldOptions}
              />
          </div>
  
          <div className="mt-2 d-flex justify-content-end">
              <button name={formInformation?.actionName} type="submit" className="btn btn-success p-2">
                Enregistrer
              </button>
          </div>
        </form> 
        }
       
      </Fragment>
    );
}


const FormFields = props => {
    let fields = props.fields || [];

    return <Fragment>
              {
                fields.map(
                  (field) => {
                            return <SingleField 
                                        options = {props.options}
                                        value = {props.value} 
                                        key={field?.name} 
                                        field={{ 
                                          ...field,
                                          handleChange : props.handleChange,
                                          handleSubFormChange :  props.handleSubFormChange
                                        }}
                                      />
                          }
                )
              }
    </Fragment>
}

export class SingleField extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      fieldInformation : {}
    }
    this.buildField = this.buildField.bind(this);
    this.findAndBuildOptions = this.findAndBuildOptions.bind(this);
    this.getFieldValue = this.getFieldValue.bind(this);
    this.buildFieldRender = this.buildFieldRender.bind(this);
  }

  componentDidUpdate(prevProps,prevState){
      if(this.props !== prevProps){
        if(this.props.field !== prevProps.field){
          this.setState({fieldInformation : this.buildField(this.props.field)})
        }
      }
  }

  findAndBuildOptions(options,dataSource){
    return options?.find(
      item => {
        return item.title === dataSource
      }
    )?.options || []
  }

  buildField(information){
    if(information?.dataServiceSource && information?.htmlElement === "select"){
      information.options =  this.findAndBuildOptions(this.props.options,information?.dataServiceSource);
    }

    if(information?.htmlElement === "dynamicForm"){
      information.options =  this.props.options;
    }

    if(information?.htmlElement === "dynamicUnChangeForm"){
      information.options =  this.props.options;
    }

    if(information?.htmlElement === "editable-label"){
      information.options =  this.props.options;
    }

    information.dataValue =  this.getFieldValue(this.props.value,information?.name,information?.selectDataValue?.name)
    
    return fieldClass(information);
  }

  getFieldValue(values,name,otherName = null){
    if(otherName){
        let value = values?.[otherName];
        if(!value){
          value = values?.[name]
        }
    }

    return values?.[name];
  }

  componentDidMount(){
    this.setState({fieldInformation : this.buildField(this.props.field)})
  }

  buildFieldRender(fieldInformation){
    let Component = null;
    switch(fieldInformation.htmlElement){
      case "input" : 
              Component = InputBuilder;  
          break;
      case "disableInput" : 
          Component = DisableInputBuilder;  
        break;
      case "select" : 
              Component = SelectBuilder;  
            break;
      case "textarea" : 
              Component = TextareaBuilder; 
            break;
      case "dynamicForm" : 
              Component = DynamicForm; 
            break;
      case "dynamicUnChangeForm" : 
            Component = DynamicUnChangeForm; 
          break;  
      case "editable-label" : 
            Component = EditableLabel; 
          break;  
      default : break;
    }
    if(Component){
     return <Component fieldInformation = {fieldInformation}/>;
    }
    return null;
  }

  render(){
    return <Fragment>
              {this.buildFieldRender(this.state.fieldInformation)}
            </Fragment>;
  }
}


export default FormContentFrame;
