const ListFormatterResponse = data => {
  if (data?.exists()) {
    const keys = Object.keys (data.val());
    const values = Object.values (data.val ());
    return values.map ((value, index) => {
      return RowFormatterResponse (keys[index], value);
    });
  }
  return [];
};

const SingleFormatterResponse = data => {
    if (data?.exists()) {
      return RowFormatterResponse (data.key, data.val())
    }
    return null;
}

const RowFormatterResponse = (key, value) => {
  return {
    ...value,
    id: key,
  };
};

const dataFormatter = {ListFormatterResponse, SingleFormatterResponse};

export default dataFormatter;
