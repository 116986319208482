const OperationResponse = (success, data, errors, message) => {
  return {
    success,
    data,
    errors,
    message,
  };
};

export default OperationResponse;
