import {useTheme} from '@mui/material/styles';
import {Drawer} from './drawer';
import React, {Fragment} from 'react';
import Divider from '@mui/material/Divider';

import './sidebar.css';
import logo from './../../../../../assets/images/logo.png';
import SideHeader from './_layouts/side-header';
import SideContent from './_layouts/side-content';
import menus from '../../../../../configs/menus.json';
import SideFooter from './_layouts/side-footer/index';
import config from '../../../../../configs/config.json';
import { useSelector } from 'react-redux';


const Sidebar = props => {
  const theme = useTheme ();
  

  const currentUser =  useSelector(state => state.auth);


  return (
    <Fragment>
      <Drawer variant="permanent" anchor="left" open={props.open}>
        <SideHeader
            appName={config?.APP_NAME || "ARCHITECT APP"}
            handleDrawerClose={props.handleDrawerClose}
            handleDrawerOpen={props.handleDrawerOpen}
            logo={logo}
            theme={theme}
            open={props.open}
          />

        <Divider/>
        <SideContent name={props.name} open={props.open} menus={menus?.back?.sidebar} />
        <Divider/>
        <SideFooter openDialog={props.openDialog} open={props.open} user={currentUser?.user} />
      </Drawer>

    </Fragment>
  );
};

export default Sidebar;
