import DefaultController from './default.controller';

export default class FeesController extends DefaultController {
  rules = [
    {title: 'name', rules: ['required', 'unique:fees:name']},
    {title: 'currency', rules: ['required']},
  ];

  constructor () {
    super ({
      path: 'fees',
    });
  }
}
