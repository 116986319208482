import DefaultController from './default.controller';

export default class RolesController extends DefaultController {
  rules = [{title: 'name', rules: ['required', 'unique:roles:name']}];

  constructor () {
    super ({
      path: 'roles',
    });
  }
}
