import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class RecipientsQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'recipients',
      path: 'recipients',
    });
  }
}
