import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const changeTaxesConfiguration = {
  name: 'changetaxes',
  title: 'Taux de changes',
  typeFrame: 'list',
  fields: [
    {
      name: 'currency1',
      title: 'Devise 1',
      col: 4,
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'currencies',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'currencies',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'currency2',
      title: 'Devise 2',
      col: 4,
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'currencies',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'currencies',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'rate',
      title: 'Taux de conversion',
      col: 4,
      type: 'number',
    },
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (changeTaxesConfiguration);
