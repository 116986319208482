import Service from '../../vendor/bases/service';

export default class RolesService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'roles',
    });
  }
}
