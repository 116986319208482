import {Route, Routes, Navigate} from 'react-router-dom';
import routesBuilder from './router-builder';
import {connect} from 'react-redux';

function AppRouter (props) {
  return (
    <Routes>
      {routesBuilder ()}
      <Route path="*" element={<Navigate to="/notfound" />} />
    </Routes>
  );
}

const mapStateToProps = state => state;

export default connect (mapStateToProps) (AppRouter);
