import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const feesConfiguration = {
  name: 'fees',
  title: 'Frais',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom de la tranche',
      col: 4,
    },
    {
      name: 'currency',
      title: 'Devise envoi',
      col: 4,
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'currencies',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'currencies',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'currency2',
      title: 'Devise reception',
      col: 4,
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'currencies',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'currencies',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'feesList',
      title: 'Frais & intervalles',
      fields: [
        {
          name: 'processFee',
          title: 'Frais de traitement',
          col: 12,
          type: 'percentage',
        },
        {
          name: 'bankFee',
          title: 'Frais bancaire',
          col: 12,
          type: 'percentage',
        },
        {
          name: 'smallAmount',
          title: 'Montant minimum',
          col: 12,
          type: 'number',
        },
        {
          name: 'maxAmount',
          title: 'Montant maximum',
          col: 12,
          type: 'number',
        },
      ],
      htmlElement: 'dynamicForm',
    },

    {
      name: 'isActive',
      title: 'Statut',
      options: {
        0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Actif'},
      },
      type: 'checkBox',
    },
  ],
  excludeToForms: ['isActive'],
  excludeToHeaders: ['feesList'],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (feesConfiguration);
