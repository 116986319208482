import {Fragment} from 'react';
import './search.css';
import { capitalizeFirstLetter } from './../../../../../bootstrap/Helpers/utils';
import TextField  from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { useSelector } from 'react-redux';
import SearchAutoCompleteZone from './_layouts/autocomplete';
import DateRangePickerValue from './_layouts/dates/simple-date';
import DateTimeRangePickerValue from './_layouts/dates/date-time';





export default function FrameSearchZone (props) {
  const currentUser = useSelector(state => state.auth);

  return (
    <Fragment>
        <div className="row mt-3 p-2">
          <div className="col-sm-9">
              <div className="d-flex">
                {
                  props.searchOptions && props.searchOptions?.length > 0 ?
                  props.searchOptions.map(
                    (option) => {
                      const renderTest = currentUser?.user?.authorities?.includes("filters:"+props.name+"_"+option?.name);
                      if(renderTest){
                        return  <DefaultSearchZone values={props.values} options={props.options} key={option.name} option={option} handleFilterFieldChange={props.handleFilterFieldChange}/>

                      }
                       return null;     
                      }
                  )  :  null

                }
              </div>
          </div>  
          <div className="col-sm-3 d-flex justify-content-end">
              <TextField
                  id="size"
                  select
                  value={props.values.size}
                  size="small"
                  onChange={(e) => {e.preventDefault(); props.handleFilterFieldChange(e,null,"size")}}
                  sx={{ width:"30%" }}
                >
                  {props.paginationOptions?.pageEntriesLength.map((length) => (
                    <MenuItem key={length} value={length}>
                      {length}
                    </MenuItem>
                  ))}
              </TextField>
          </div>
        
        </div>
    </Fragment>
  );
}

export function FrameSearchZoneCopy (props) {
  return (
    <Fragment>
        <div className="row mt-3 p-2">
          <div className="col-sm-9">
              <div className="d-flex">
                {
                  props.searchOptions && props.searchOptions?.length > 0 ?
                  props.searchOptions.map(
                    (option) => {
                        return  <DefaultSearchZone values={props.values} options={props.options} key={option.name} option={option} handleFilterFieldChange={props.handleFilterFieldChange}/>
                  })  : null
                }
              </div>
          </div>  
          <div className="col-sm-3 d-flex justify-content-end">
              <TextField
                  id="size"
                  select
                  value={props.values.size}
                  size="small"
                  onChange={(e) => {
                    e.preventDefault(); 
                    props.handleFilterFieldChange(e,null,"size")}}
                  sx={{ width:"30%" }}
                >
                  {props.paginationOptions?.pageEntriesLength.map((length) => (
                    <MenuItem key={length} value={length}>
                      {length}
                    </MenuItem>
                  ))}
              </TextField>
          </div>
        
        </div>
    </Fragment>
  );
}


export function DefaultSearchZone(props){
  let option = props.option;
  const labelTitle = option?.title || option?.name;
  const options = option?.options  || props.options.find(elt => elt.title === option.optionsTarget)?.options;
  const defaultValue = props.values[option?.name];

  if(option?.type === "select"){
      return    <SearchAutoCompleteZone 
                  changeSearchValue={props.handleFilterFieldChange} 
                  data={{
                    title : labelTitle,
                    name : option?.name,
                    options : options || [],
                    value : defaultValue
                  }}/>
  }

  if(option?.type === "interval"){
    return <DateRangePickerValue
                changeSearchValue={props.handleFilterFieldChange} 
                data={{
                  title : labelTitle,
                  name : option?.name,
                  options : options || [],
                  value : defaultValue
                }}
            />
  }

  if(option?.type === "interval-time"){
    return <DateTimeRangePickerValue 
                changeSearchValue={props.handleFilterFieldChange} 
                data={{
                  title : labelTitle,
                  name : option?.name,
                  options : options || [],
                  value : defaultValue
                }}
            />
  }

  return   <TextField
              label={capitalizeFirstLetter(labelTitle)}
              id={option?.name}
              value={defaultValue || ""}
              size="small"
              onChange={(e) => {props.handleFilterFieldChange(e,null,option.name)}}
              name={option?.name}
              sx={{ width : 200, mr:1 }}
            />
  
  
}


