import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const usersConfiguration = {
  name: 'users',
  title: 'Utilisateur(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'roles',
      title: 'rôle(s)',
      htmlElement: 'select',
      fieldToTake: 'roles',
      options: [],
      multiple: true,
      dataServiceSource: 'roles',
      selectDataValue: {
        name: 'roles',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'firstName',
      title: 'nom(s)',
      col: 6,
    },
    {
      name: 'lastName',
      title: 'prenom(s)',
      col: 6,
    },
    {
      name: 'email',
      type: 'email',
      col: 4,
    },
    {
      name: 'tel',
      title: 'Contact',
      type: 'phone',
      col: 4,
    },
    {
      name: 'password',
      title: 'Mot de passe',
      type: 'password',
      col: 4,
    },
    {
      name: 'isActive',
      title: 'Est actif?',
      type: 'checkbox',
      options: {
        0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Actif'},
      },
    },
  ],
  excludeToForms: [],
  excludeToHeaders: ['password'],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (usersConfiguration);
