import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class TransactionsQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'transactions',
      path: 'transactions',
    });
  }
}
