import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const rolesConfiguration = {
  name: 'resources',
  title: 'Role(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom',
    },
    {
      name: 'isActive',
      title: 'Statut',
      options: {
        0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Actif'},
      },
      type: 'checkBox',
    },
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (rolesConfiguration);
