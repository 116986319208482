import defaultListAction from  './default-elements/default-list-action.json';
import defaultFrameAction from  './default-elements/default-frame-action.json';
import buildHeader from './build-header';
import { excludeFieldsFromArray } from '../../bootstrap/Helpers/utils';

export function BaseConfiguration(properties){
 
  return {
   /* mainComponent : '',
    listComponent : '',
    singleComponent : '',
    formComponent  : ''*/
    name : properties.name,
    title : properties.title ?? properties.name,
    frameActions : properties.frameActions ?? defaultFrameAction,
    typeFrame : properties?.typeFrame,
    fields : properties?.fields ?? [],
    actions : properties?.actions ?? null,
    excludeToHeaders : properties?.excludeToHeaders, 
    excludeToForms : properties?.excludeToForms,
    filterOptions : properties?.filterOptions ?? [],
    options : properties?.options || [],
    dialogsConfig : properties?.dialogsConfig || null,
    formConfig : properties?.formConfig || null,
    elementsType : properties?.listElementsType || null,
    listSingleElementRender : properties?.listSingleElementRender,
    componentBeforeRenderList : properties?.componentBeforeRenderList || null,
    hideSidebar : properties?.hideSidebar,
    hidePageTitle : properties?.hidePageTitle,
    hideBar : properties?.hideBar
  }
}


export function listConfiguration(listProperties){

    let elementsType = listProperties?.elementsType ?? "table";
    let fields = listProperties?.columns ?? excludeFieldsFromArray(listProperties?.fields,listProperties?.excludeToHeaders) ?? [];
    return {
      fetchElement : elementsType,
      dataColumns : buildDefaultHeader(fields),  
      actions : listProperties?.actions ?? defaultListAction,
      items : listProperties?.fetchData?.items ?? [],
      paginationOptions : {
        selectedPage :  listProperties?.fetchData?.currentPage ?? 0,
        pageEntriesLength : [5,10,25,50,100,1000],
        defaultEntriesLength : listProperties?.fetchData?.size ?? 5,
        total : listProperties?.fetchData?.totalItems,
        totalPage :  listProperties?.fetchData?.totalPages ?? 1 
      },
      filterOptions : listProperties?.filterOptions ?? [],
      searchOptionsValue : listProperties?.searchOptions,
      options : listProperties?.options || [],
      listSingleElementRender : listProperties?.listSingleElementRender || null,
      componentBeforeRenderList : listProperties?.componentBeforeRenderList || null
    }
}

export function singleConfiguration(singleProperties){
  return {
    headers : singleProperties?.headers,
    data : singleProperties?.data,
  }
}

export function formConfiguration(formProperties){
  return {

  }
}

function buildDefaultHeader(fields){
  return fields.map(
    field => buildHeader(field)
  )
}

