import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class AuthoritiesQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'authorities',
      path: 'authorities',
    });
  }
}
