import apiConfig from '../../configs/config';
//import {extractClassName} from '../../bootstrap/Helpers/utils';
import Query from './query-1';

export default class DefaultQuery extends Query {
  // name = extractClassName (this.constructor.name, 'Query');
  constructor (params = null) {
    super (params?.path);
    this.name = params?.name;
    this.path = params?.path || this.ownerBuilder ().path;
  }

  async create (credentials) {
    return await this.createMethod (this.buildPath (''), credentials);
  }

  async readAll (searchOptions = null) {
    return await this.getMethod (this.buildPath (''), searchOptions);
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`));
  }

  async update (id, payLoad) {
    return await this.updateMethod (this.buildPath (`${id}`), payLoad);
  }

  async delete (id) {
    return await this.deleteMethod (this.buildPath (`${id}`));
  }

  async loadList (params) {
    return await this.getMethod (this.buildPath (''));
  }

  ownerBuilder () {
    return {
      path: this.name?.toLowerCase (),
      payLoad: null,
    };
  }

  buildPath (path, anotherName = null) {
    return `${apiConfig.api}${anotherName ? '/' + anotherName : '/' + this.path}${path && path !== '' ? '/' + path : ''}`;
  }
}
