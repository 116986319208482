import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../../../../../bootstrap/Helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import { Fragment, useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Tooltip, { tooltipClasses }  from '@mui/material/Tooltip';
import { StarBorder } from '@mui/icons-material';
import { useSelector } from 'react-redux';


export default function SideContent (props) {
  const menus = props.menus || [];
  const currentUser = useSelector(state =>state.auth);
  return (
    <div className="menu_container mx-0 mb-5">
          <div className="menu_items mb-3">
            { menus.map (item => {
                //if(currentUser.user?.authorities?.includes("group_menus:"+item.name?.toLowerCase())){ 
                  return <MenusGroup name={props.name} authorizations={currentUser.user?.authorities} open={props.open} key={item?.groupTitle} menuGroup={item} />;
                //}
                //return null;
              }
             )
            }
          </div>      
    </div>
    
  );
}

const MenusGroup = props => {
  const group = props.menuGroup || null;
  return (
      <Fragment>
        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              props?.open ? 
            <ListSubheader component="div" id="nested-list-subheader">
              {group?.groupTitle}
            </ListSubheader> : null
          }
        >
          {
            group?.groupElements?.map(
                item => {
                    //if(props.authorizations.includes("menus:"+item.key?.toLowerCase())){
                      return <MenuGroupItem open={props.open} key={item.title} item={item}/>  
                  //  }
                   // return null;
                }
            )
          }
        </List>
        <Divider/>  
      </Fragment>
     
  );
};


const MenuGroupItem = props => {
  const item  = props.item;
  const route = item.route ? item.route.toLowerCase() :  item.path.toLowerCase(); 

 

  const MenuLink =  item?.childArray && item?.childArray?.length > 0 ? MenuItemWithCollapse : SimpleMenuItem
  
    return <MenuLink properties={{ 
                  ...item,
                  route : route
            }}
            open = {props.open} 
          />
}


const MenuItemWithCollapse = props => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  }

  return <CustomTooltip 
              placement="right"
              title ={
                !props.open?
                
                <CollapseChildrenRender iconVisible={true} textColor="white" elements={props.properties.childArray}/> : null
              }
          >
            <Fragment> 
              <ListItemButton 
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? 'initial' : 'center',
                    px: 2,
                  }}
                  onClick={handleClick}>
                  <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: props.open ? 3 : 'auto',
                        justifyContent: 'center',
                        backgroundColor : '#000039',
                        color:'rgba(242,242,245,1)',
                        padding :'8px'
                      }}
                    >
                      <FontAwesomeIcon icon={props.properties?.icon ?? "check"}/>    
                  </ListItemIcon>
                  <ListItemText primary={capitalizeFirstLetter(props.properties?.title)} sx={{visibility: props.open ? 'visible' : 'hidden'}} />
                    {
                      props.open ? 
                        open ? <ExpandLess /> : <ExpandMore /> : null
                    }
              </ListItemButton>
            { props.open ? 
              <Collapse in={open} timeout="auto" unmountOnExit>
                  <CollapseChildrenRender textColor="#000039" elements={props.properties.childArray}/>     
              </Collapse> : null
            }
           </Fragment>
         </CustomTooltip>
}

const CollapseChildrenRender = props => {
  const elements = props.elements || [];
  const currentUser = useSelector(state => state.auth);
  return    <List component="div" disablePadding>
              {
                elements.map(
                  element => {
                    const childRoute = element?.route ? element?.route.toLowerCase() : element?.title.toLowerCase();
                    if(!currentUser?.user.authorities.includes("menus:"+element.key)){
                      return null;
                    }
                    return <Link className="text-decoration-none" key={element?.title} to={"/"+childRoute}>
                                <ListItemButton sx={{ pl: 4,  textDecoration: 'none',color : props.textColor }}>
                                  {props.iconVisible ?
                                    <ListItemIcon sx={{ color : props.textColor }}>
                                      <StarBorder />
                                    </ListItemIcon> : null
                                  }
                                    <ListItemText 
                                        primary={capitalizeFirstLetter(element?.title)} 
                                    />
                                </ListItemButton>
                          </Link>
                  }
                )
              }
          </List>
}  



const SimpleMenuItem = props => {
  return <CustomTooltip title={!props.open ? capitalizeFirstLetter(props.properties?.title) : ""} placement="right">
            <Link to={"/"+props.properties?.route} className="link">
              <ListItem  disablePadding sx={{display: 'block'}}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? 'initial' : 'center',
                    px: 2,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : 'auto',
                      justifyContent: 'center',
                      backgroundColor : '#000039',
                      color:'rgba(242,242,245,1)',
                      padding :'8px'
                    }}
                  >
                    <FontAwesomeIcon icon={props.properties?.icon ?? "check"}/>    
                  </ListItemIcon>
                  <ListItemText primary={capitalizeFirstLetter(props.properties?.title)} sx={{opacity: props.open ? 1 : 0}} />
                </ListItemButton>
                
                    
              </ListItem>
            </Link>
          </CustomTooltip>
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip title={props.title} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));


