import Service from '../../vendor/bases/service';
import UsersQuery from './../queries/users.query';

export default class UsersService extends Service {
  constructor (params) {
      super({
        ...params,
        name : "users"
      });
      this.query = new UsersQuery();
  }
  

  login = async credentials => {
    const payload = this.getFormValues (credentials);
    let queryResponse = await this.query.login (payload);
    if(!queryResponse?.success){
      if(queryResponse?.errors?.length > 0){
        queryResponse.errors = this.giveErrorToTheGoodField(queryResponse.errors,payload);
      }
    }else{
      localStorage.setItem ('user', JSON.stringify (queryResponse?.data));
    }

    return queryResponse;    
  }

  logout = async () => {
    localStorage.removeItem('user');
    return  {
      success : true,
      data : null,
      message : "Utilisateur déconnecté avec succès"
    }
  }

  async changePassword(id,credentials){
    const payload = this.getFormValues (credentials);
    let queryResponse = await this.query.changePassword (id,payload);

    if(!queryResponse?.success && queryResponse?.errors?.length > 0){
      queryResponse.errors = this.giveErrorToTheGoodField(queryResponse.errors,payload)
    }
    return queryResponse;
  }
}
