import BaseBackComponent from './../base-back-component';

export default class RecipientsComponent extends BaseBackComponent {
  name = 'Recipients';

  options = [
    {
      title: 'banks',
      keys: ['name'],
    },
    {
      title: 'currencies',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    return data;
  }
}
