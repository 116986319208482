let predefinePeriods = [
  {label: "Aujourd'hui", value: 'today'},
  {label: 'Hier', value: 'yesterday'},
  {label: 'Semaine en cours', value: 'currentWeek'},
  {label: 'Semaine flottante', value: 'floatingWeek'},
  {label: 'Semaine précedente', value: 'prevWeek'},
  {label: 'Mois en cours', value: 'currentMonth'},
  {label: 'Mois flottant', value: 'floatingMonth'},
  {label: 'Mois précedent', value: 'prevMonth'},
  {label: 'Année en cours', value: 'currentYear'},
  {label: 'Année flottante', value: 'floatingYear'},
  {label: 'Année précedente', value: 'prevYear'},
];

export {predefinePeriods};
