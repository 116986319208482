import DefaultController from './default.controller';

export default class CurrenciesController extends DefaultController {
  rules = [{title: 'name', rules: ['required', 'unique:currencies:name']}];

  constructor () {
    super ({
      path: 'currencies',
    });
  }
}
