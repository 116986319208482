import BaseBackComponent from './../base-back-component';

export default class UsersComponent extends BaseBackComponent {
  name = 'Users';

  options = [{title: 'roles', keys: ['name']}];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    return data;
  }
}
