import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const dashboardFrameConfig = {
  name: 'dashboard',
  title: 'tableau de bord',
};

export default BaseConfiguration (dashboardFrameConfig);
