import DefaultController from './default.controller';

export default class ResourcesController extends DefaultController {
  rules = [{title: 'name', rules: ['required', 'unique:resources:name']}];

  constructor () {
    super ({
      path: 'resources',
    });
  }
}
