import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const affiliationsConfiguration = {
  name: 'affiliations',
  title: 'Affiliation(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom & prénom affilié',
      col: '6',
    },
    {
      name: 'client',
      title: 'Parrain',
      col: 6,
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'clients',
      isObject: true,
      fieldsSelected: ['Nom'],
      selectDataValue: {
        name: 'client',
        options: {
          valueOptions: ['id'],
          labelOptions: ['Nom'],
        },
      },
    },
    {
      name: 'tel',
      title: 'Téléphone',
      col: '4',
      type: 'phone',
    },
    {
      name: 'email',
      title: 'Email',
      col: 4,
    },
    {
      name: 'address',
      title: 'Adresse',
      col: 4,
    },
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (affiliationsConfiguration);
