import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class ChangetaxesQuery extends DefaultFirebaseQuery {
  constructor (params = null) {
    super ({
      name: 'changetaxes',
      path: 'changetaxes',
    });
  }
}
