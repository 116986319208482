import { ref, get } from 'firebase/database';
import db from '../db';

const emailValidator = sentValue => {
  let errorMessage = null;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailPattern.test (sentValue)) {
    errorMessage = 'Ce champ doit être de type email';
  }
  return errorMessage;
};

const requiredValidator = sentValue => {
  let errorMessage = null;
  if (!sentValue) {
    errorMessage = 'Une valeur est requise pour ce champ';
  }
  return errorMessage;
};

const minValidator = (sentValue, minValue) => {
  let errorMessage = null;
  if (sentValue < minValue) {
    errorMessage = 'La valeur minimum pour champ est : ' + minValue;
  }
  return errorMessage;
};

const maxValidator = (sentValue, maxValue) => {
  let errorMessage = null;
  if (sentValue > maxValue) {
    errorMessage = 'La valeur maximum pour champ est : ' + maxValue;
  }
  return errorMessage;
};

const uniqueValidator = async (
  sentValue,
  collectionName,
  fieldName,
  existingKey = null
) => {
  let errorMessage = null;
  const queryExists = await get(ref (db, collectionName));
  if (queryExists?.exists () && queryExists?.val ()) {
    const existingData = Object.values (queryExists.val ()).find (
      data => data[fieldName] === sentValue
    );

    if (existingData) {
      errorMessage = 'Une entrée dispose déjà de cette valeur : ' + sentValue;
      if (existingKey && existingData.key === existingKey) {
        errorMessage = null;
      }
    }
  }
  return errorMessage;
};

const VALIDATORS = {
  emailValidator,
  requiredValidator,
  minValidator,
  maxValidator,
  uniqueValidator,
};

export default VALIDATORS;
