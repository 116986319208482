import Service from '../../vendor/bases/service';

export default class ChangetaxesService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'changetaxes',
    });
  }
}
