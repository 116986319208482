import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fragment } from 'react';
import { FormBuilder } from '../Form';

export default function DialogBuilder(props){
    const properties= props.dialogProperties?.dialogContent;

    const fullScreen = useMediaQuery(props.theme.breakpoints.down(properties?.size || "sm"));
    return  <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%' } }}
                maxWidth={properties?.size || "sm"}    
                fullScreen={fullScreen}
                open={props.dialogProperties?.openDialog}
                onClose={props.dialogProperties?.closeDialog}
                aria-labelledby="responsive-dialog-title"
            >
            <DialogTitle id="responsive-dialog-title">
                {properties?.dialogTitle}
            </DialogTitle>
            <DialogElement {...props}/>
         </Dialog>
}

const DialogElement = props => {
    let DialogComponent = null; 
    const properties= props.dialogProperties?.dialogContent;
    switch(properties?.typeContent){
        case 'text' : 
                DialogComponent = DialogText;
            break;
        case 'otherContent' : 
                DialogComponent = DialogOtherContent;
            break;
        default : break;
    }

    return <Fragment>
                <DialogContent>
                    <DialogComponent informationContent={properties} />
                </DialogContent>
                {
                    properties?.typeContent === "text" && properties?.type === "action"?  
                    <DialogActions>
                        <Button onClick={props.dialogProperties?.closeDialog}>
                            ANNULER
                        </Button>
                        <Button  id={properties.reference} name={properties?.actionName} onClick={properties?.validAction}>
                            ACCEPTER
                        </Button>
                    </DialogActions> : null
                }
               
            </Fragment>
}

const DialogText = props => {  
    const information = props?.informationContent;

    return <DialogContentText>
                {information?.dialogText}
            </DialogContentText>
}

const DialogOtherContent = props => {  
    const information = props?.informationContent;
    return <Fragment>
                {
                    information.type === "form" ? 
                        <DialogWithForm data={information?.fields} /> : 
                        <DialogPersonalDialog data={information} />
                }
            </Fragment>
}

const DialogWithForm = props => {
    return <FormBuilder formInformation={props.data} />;
}


const DialogPersonalDialog = props => {
    const DialogRender = props.data?.renderComponent;
    return <div>
                <DialogRender data={props.data?.data}/>
            </div>
}