import moment from 'moment';

export function capitalizeFirstLetter (string) {
  return string.charAt (0).toUpperCase () + string.slice (1);
}

export function extractClassName (className, toRemove = null) {
  let newName = null;
  if (toRemove) {
    newName = className.replace (toRemove, '');
  }
  return capitalizeFirstLetter (newName || className);
}

export function getBase64 (file) {
  return new Promise (resolve => {
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader ();

    // Convert the file to base64 text
    reader.readAsDataURL (file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve (baseURL);
    };
  });
}

export function excludeFieldsFromArray (fieldsArray, toExcludeArray = []) {
  let finalArray = fieldsArray;
  toExcludeArray.forEach (excludeItem => {
    finalArray = finalArray.filter (field => field.name !== excludeItem);
  });

  return finalArray;
}

export function checkConditions (condition1, operation = '||', condition2) {
  return operation === '&&'
    ? condition1 && condition2
    : condition1 || condition2;
}

export function defaultFormatChartValue (donnees) {
  let series = [];
  let dataset = [];
  let xAxis = [];

  if (donnees) {
    for (let data in donnees) {
      const valueData = donnees[data];

      dataset.push ({
        [data]: valueData,
      });

      series.push (valueData);
      xAxis.push (data);
    }
  }

  return {xAxis, series, dataset};
}

export function getFixedDateInterval (periodChoose, withTime = false) {
  let period = '';
  const currentDate = new Date ();
  switch (periodChoose) {
    case 'today':
      period = buildTodayInterval (currentDate, withTime);
      break;
    case 'yesterday':
      period = buildYesterdayInterval (currentDate, withTime);
      break;
    case 'currentWeek':
      period = buildCurrentWeekInterval (currentDate, withTime);
      break;
    case 'floatingWeek':
      period = buildFloatingWeekInterval (currentDate, withTime);
      break;
    case 'prevWeek':
      period = buildPrevWeekInterval (currentDate, withTime);
      break;
    case 'currentMonth':
      period = buildCurrentMonthInterval (currentDate, withTime);
      break;
    case 'floatingMonth':
      period = buildFloatingMonthInterval (currentDate, withTime);
      break;
    case 'prevMonth':
      period = buildPrevMonthInterval (currentDate, withTime);
      break;
    case 'currentYear':
      period = buildCurrentYearInterval (currentDate, withTime);
      break;
    case 'floatingYear':
      period = buildFloatingYearInterval (currentDate, withTime);
      break;
    case 'prevYear':
      period = buildPrevYearInterval (currentDate, withTime);
      break;
    default:
      break;
  }
  return period;
}

function buildTodayInterval (currentDate, withTime = false) {
  if (withTime) {
    return (
      moment (currentDate.setHours (0, 0, 0)).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (currentDate.setHours (23, 59, 59)).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (currentDate).format ('YYYY-MM-DD') +
    ',' +
    moment (currentDate).format ('YYYY-MM-DD')
  );
}

function buildYesterdayInterval (currentDate, withTime = false) {
  currentDate.setDate (currentDate.getDate () - 1);
  if (withTime) {
    return (
      moment (currentDate.setHours (0, 0, 0)).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (currentDate.setHours (23, 59, 59)).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (currentDate).format ('YYYY-MM-DD') +
    ',' +
    moment (currentDate).format ('YYYY-MM-DD')
  );
}

function buildCurrentWeekInterval (currentDate, withTime = false) {
  const firstWeekDay = currentDate.getDate () - (currentDate.getDay () - 1); // First day is the day of the month - the day of the week

  const lastWeekDay = firstWeekDay + 6; // last day is the first day + 6

  let start = new Date (currentDate.setDate (firstWeekDay));
  let end = new Date (currentDate.setDate (lastWeekDay));

  if (withTime) {
    start.setHours (0, 0, 0);
    end.setHours (23, 59, 59);
    return (
      moment (start).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (end).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (start).format ('YYYY-MM-DD') +
    ',' +
    moment (end).format ('YYYY-MM-DD')
  );
}

function buildFloatingWeekInterval (currentDate, withTime = false) {
  const firstWeekDay = currentDate.getDate () - 7; // First day is the day of the month - the day of the week

  const lastWeekDay = currentDate.getDate (); // last day is the first day + 6

  let start = new Date (currentDate.setDate (firstWeekDay));
  let end = new Date (currentDate.setDate (lastWeekDay));

  if (withTime) {
    return (
      moment (start).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (end).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (end).format ('YYYY-MM-DD') +
    ',' +
    moment (end).format ('YYYY-MM-DD')
  );
}

function buildPrevWeekInterval (currentDate, withTime = false) {
  currentDate.setDate (currentDate.getDate () - 7);
  const firstWeekDay = currentDate.getDate () - (currentDate.getDay () - 1); // First day is the day of the month - the day of the week

  const lastWeekDay = firstWeekDay + 6; // last day is the first day + 6

  let start = new Date (currentDate.setDate (firstWeekDay));
  let end = new Date (currentDate.setDate (lastWeekDay));

  if (withTime) {
    start.setHours (0, 0, 0);
    end.setHours (23, 59, 59);
    return (
      moment (start).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (end).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (start).format ('YYYY-MM-DD') +
    ',' +
    moment (end).format ('YYYY-MM-DD')
  );
}

function buildCurrentMonthInterval (currentDate, withTime = false) {
  let firstDay = new Date (
    currentDate.getFullYear (),
    currentDate.getMonth (),
    1
  );
  let lastDay = new Date (
    currentDate.getFullYear (),
    currentDate.getMonth () + 1,
    0
  );

  if (withTime) {
    firstDay.setHours (0, 0, 0);
    lastDay.setHours (23, 59, 59);
    return (
      moment (firstDay).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (lastDay).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (firstDay).format ('YYYY-MM-DD') +
    ',' +
    moment (lastDay).format ('YYYY-MM-DD')
  );
}

function buildFloatingMonthInterval (currentDate, withTime = false) {
  let firstDay = new Date (
    currentDate.getFullYear (),
    currentDate.getMonth () - 1,
    currentDate.getDate ()
  );
  let lastDay = new Date (
    currentDate.getFullYear (),
    currentDate.getMonth (),
    currentDate.getDate ()
  );

  if (withTime) {
    firstDay.setHours (0, 0, 0);
    lastDay.setHours (23, 59, 59);
    return (
      moment (firstDay).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (lastDay).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (firstDay).format ('YYYY-MM-DD') +
    ',' +
    moment (lastDay).format ('YYYY-MM-DD')
  );
}

function buildPrevMonthInterval (currentDate, withTime = false) {
  let firstDay = new Date (
    currentDate.getFullYear (),
    currentDate.getMonth () - 1,
    1
  );
  let lastDay = new Date (
    currentDate.getFullYear (),
    currentDate.getMonth (),
    0
  );

  if (withTime) {
    firstDay.setHours (0, 0, 0);
    lastDay.setHours (23, 59, 59);
    return (
      moment (firstDay).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (lastDay).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (firstDay).format ('YYYY-MM-DD') +
    ',' +
    moment (lastDay).format ('YYYY-MM-DD')
  );
}

function buildCurrentYearInterval (currentDate, withTime = false) {
  const startDate = new Date (currentDate.getFullYear (), 0, 1);
  const endDate = new Date (currentDate.getFullYear (), 11, 31);

  if (withTime) {
    startDate.setHours (0, 0, 0);
    endDate.setHours (23, 59, 59);
    return (
      moment (startDate).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (endDate).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (startDate).format ('YYYY-MM-DD') +
    ',' +
    moment (endDate).format ('YYYY-MM-DD')
  );
}

function buildFloatingYearInterval (currentDate, withTime = false) {
  const startDate = new Date (
    currentDate.getFullYear () - 1,
    currentDate.getMonth (),
    currentDate.getDay ()
  );
  const endDate = new Date (
    currentDate.getFullYear (),
    currentDate.getMonth (),
    currentDate.getDay ()
  );

  if (withTime) {
    startDate.setHours (0, 0, 0);
    endDate.setHours (23, 59, 59);
    return (
      moment (startDate).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (endDate).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (startDate).format ('YYYY-MM-DD') +
    ',' +
    moment (endDate).format ('YYYY-MM-DD')
  );
}

function buildPrevYearInterval (currentDate, withTime = false) {
  const startDate = new Date (currentDate.getFullYear () - 1, 0, 1);
  const endDate = new Date (currentDate.getFullYear () - 1, 11, 31);

  if (withTime) {
    startDate.setHours (0, 0, 0);
    endDate.setHours (23, 59, 59);
    return (
      moment (startDate).format ('YYYY-MM-DD HH:mm:ss') +
      ',' +
      moment (endDate).format ('YYYY-MM-DD HH:mm:ss')
    );
  }
  return (
    moment (startDate).format ('YYYY-MM-DD') +
    ',' +
    moment (endDate).format ('YYYY-MM-DD')
  );
}
