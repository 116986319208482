// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';

const firebaseConfigDev = {
  apiKey: 'AIzaSyBCBCrkH1zbcHNc8YjFo_AZINuUvSyqDdU',
  authDomain: 'well-transfer-d9757.firebaseapp.com',
  projectId: 'well-transfer-d9757',
  storageBucket: 'well-transfer-d9757.appspot.com',
  messagingSenderId: '328781588427',
  appId: '1:328781588427:web:e8a61c416d73c03bdd384e',
  measurementId: 'G-CEN4GHMVC5',
  databaseURL: 'https://well-transfer-d9757-default-rtdb.europe-west1.firebasedatabase.app',
};

// Initialize Firebase
const firebaseApp = initializeApp (firebaseConfigDev);

export default firebaseApp;
