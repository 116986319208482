import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class RolesQuery extends DefaultFirebaseQuery {
  constructor (params = null) {
    super ({
      name: 'roles',
      path: 'roles',
    });
  }
}
