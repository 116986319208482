export default function paginateData(data, paginationOptions = null){
    const currentPage = paginationOptions?.currentPage || 1;
    const size = paginationOptions?.size || 10;
    const totalPage = splitNumber(data?.length, size);
    
    return {
        items : GFG(data,currentPage,size),
        currentPage : currentPage || 0,
        "totalItems": data?.length,
        "totalPages": totalPage,
        "size": size,
        "firstPage": currentPage === 0,
        "lastPage": (totalPage - 1) === currentPage,
        "empty": data?.length <= 0
    }

}

function splitNumber(total,perPage){
    let totalPage = Math.floor(total / perPage);
    let rest = total % perPage;
    
    if(rest > 0){
        totalPage ++;
        if(rest > perPage){
            splitNumber(rest,perPage);
        }
    }
    
    return totalPage || 0;
}


function GFG(array, currentPage, pageSize) {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return array?.slice(startIndex, endIndex);
}