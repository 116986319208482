import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Fragment} from 'react';

export default function SideHeader (props) {
  return (
    <AppBar
      sx={{
        background: 'linear-gradient(90deg, rgba(242,242,245,1) 0%, rgba(243,243,247,1) 35%, rgba(238,244,245,1) 100%)',
        color: '#000039',
      }}
      position="static"
    >
      <header className="sidebar-header d-flex">
        {props.open
          ? <Fragment>
              <div className="image-text">
                <span className="image">
                  <img src={props.logo} alt="" />
                </span>

                <div className="text logo-text">
                  <span className="name"> {props.appName}</span>
                </div>
              </div>
            </Fragment>
          : null}

        <i className="toggle">
          {props.open
            ? <IconButton
                size="medium"
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{mr: 0}}
                onClick={props.handleDrawerClose}
              >
                <FontAwesomeIcon icon="chevron-circle-left" />
              </IconButton>
            : <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{
                  ml: '5px',
                }}
                onClick={props.handleDrawerOpen}
              >
                <FontAwesomeIcon icon="chevron-circle-right" />
              </IconButton>}
        </i>
      </header>
    </AppBar>
  );
}
