import DefaultController from './default.controller';

export default class BanksController extends DefaultController {
  rules = [{title: 'name', rules: ['required', 'unique:banks:name']}];

  constructor () {
    super ({
      path: 'banks',
    });
  }
}
