import React from 'react';
import './notfound.css';

const NotfoundComponent = () => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>Oops!</h1>
          <h2>404 - Page introuvable</h2>
        </div>
      </div>
    </div>
  );
};

export default NotfoundComponent;
