import Service from './../../vendor/bases/service';

class DashboardService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'dashboard',
    });
  }
}

export default DashboardService;
