import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class ClientsQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'clients',
      path: 'clients',
    });
  }
}
