export default function routeGuards(guards, params = null){
    let navigateTo = null;
    guards?.forEach(
        (item) => {
            const functionToCall = require(`../../app-router/protections/${item.toLowerCase()}`).default;
            navigateTo = functionToCall(params);
        }
    );
     
    return navigateTo;  
}