import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import menus from  './../../../../../configs/menus.json';
import { capitalizeFirstLetter } from '../../../../../bootstrap/Helpers/utils';
import { Link } from 'react-router-dom';
import './config-side.css';
import { useSelector } from 'react-redux';

const drawerWidth = 280;


const DrawerHeader = styled ('div') (({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing (0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function ConfigSide (props) {
  const theme = useTheme ();
  const currentUser = useSelector(state => state.auth);
  let configMenus = menus?.config || []; 
  return (
    <Box sx={{display: 'flex'}}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={props.open}
      >
        <DrawerHeader>
          <IconButton onClick={props.handleDrawerClose}>
            {theme.direction === 'rtl'
              ? <ChevronLeftIcon />
              : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {configMenus.map ((menu) => {
              /*if(!currentUser.user.authorities?.includes("menus:"+menu.key)){
                return null;
              }*/
              return (
                  <ListItem key={menu.name} disablePadding>
                      <ListItemButton>
                          <Link className="config-menu d-flex text-dark" to={menu?.path ? "/"+menu?.path.toLowerCase() : "/"+menu?.name.toLowerCase()}>
                              <ChevronRightIcon />
                              <ListItemText primary={capitalizeFirstLetter(menu.name)} />
                          </Link>
                      </ListItemButton>
                  </ListItem>
                )}
          )}
        </List>
      </Drawer>
    </Box>
  );
}
