import { TextField } from "@mui/material";
import { capitalizeFirstLetter } from "../../../../bootstrap/Helpers/utils";
import React  from 'react';


export default function TextAreaBuilder(props){
   let inputInformation = props?.fieldInformation;
    return  <BaseInput render={TextAreaComponent} fieldInformation={inputInformation}/>
} 

class BaseInput extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            value : null,
            error : null
        }
        this.setValue = this.setValue.bind(this);
        this.setError = this.setError.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    async setValue(value){
       await this.setState({value: value});
    }

    async setError(error){
        await this.setState({error: error});
     }

    async componentDidUpdate(prevProps,prevState){
        if(this.props.fieldInformation !== prevProps.fieldInformation){
            if(this.props.fieldInformation?.defaultValue?.value !== prevProps.fieldInformation?.defaultValue?.value){
                await this.setValue(this.props.fieldInformation?.defaultValue?.value);
            }

            if(this.props.fieldInformation?.defaultValue?.errors !== prevProps.fieldInformation?.defaultValue?.errors){
                await this.setError(this.props.fieldInformation?.defaultValue?.errors);
            }
        }
    }

    changeValue = async (e,value = null,name = null) => {
       await this.props?.fieldInformation?.handleChange(e,value,name)
    }

    render(){
        let inputInformation = this.props?.fieldInformation;
        const Render = this.props.render;
        return <Render error={this.state.error} value={this.state.value} changeValue={this.changeValue} fieldInformation={inputInformation}/>
    }
}


function TextAreaComponent (props) {
    let inputInformation = props?.fieldInformation;

        const changeInputValue = (e) => {
            e.preventDefault();
            props.changeValue(e);
        }

        return  <div className={`form-group col-${inputInformation?.col} my-2`}>
                    <TextField
                        error={props.error && props.error.length > 0}
                        id={inputInformation?.id}
                        label={capitalizeFirstLetter(inputInformation?.label)}
                        name={inputInformation?.name}
                        type={inputInformation?.type}
                        fullWidth
                        onChange={changeInputValue}
                        value={props.value || ""}
                        helperText={props.error || null}
                        InputProps={{ inputProps: { min: inputInformation?.minValue, max: inputInformation?.maxValue } }}
                        disabled={inputInformation?.disable}
                        multiline
                        maxRows={4}
                    />  
                </div> 
}
