import React, {Fragment} from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import FrameBuilder from './../../builders/Frame/index';
import SnackbarBuilder from './_layouts/snackbar';
import DialogBuilder from './../../builders/Dialog/index';
import { useTheme } from '@mui/material/styles';
import './assets/styles.css';
import DialogInfo from './../../base-component-class/dialog.class';
import { Fab} from '@mui/material';
import AddIcon  from '@mui/icons-material/Settings';
import ConfigDrawer from './_layouts/config-side/index';
import Sidebar from './_layouts/sidebar/index';
import PageTitle from './_layouts/title/index';
//import { Copyright } from './_layouts/copyright';
import { useSelector } from 'react-redux';


const fabStyle = {
  position: 'absolute',
  top: 45,
  right: 35,
};


export default function Template(props){
  const theme = useTheme();
  const currentUser = useSelector(state => state.auth);

  return <BaseBackTemplate authorizations={currentUser?.user?.authorities} theme={theme} {...props}/>
}

class BaseBackTemplate extends React.Component {
  constructor (props) {
    super (props);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.handleConfigDrawerOpen = this.handleConfigDrawerOpen.bind(this);
    this.handleConfigDrawerClose = this.handleConfigDrawerClose.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);

    this.state = {
      snackBarState : {
        isOpen : false,
        onClose : this.closeSnackBar,
        color : null
      },
      configSidebarState : {
        isOpen : false 
      },
      dialogProperties :this.props.dialogInformation,
      drawerProperties : {
        isOpen : true,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props !== prevProps){
      if(this.props.dialogInformation !== prevProps.dialogInfo ){
        this.setState({
          dialogProperties : this.props.dialogInformation
        })
      }

      if(this.props.snackBarInformation !== prevProps.snackBarInformation){
        this.setState({snackBarState : {
          ...this.state.snackBarState,
          isOpen : this.props.snackBarInformation?.isOpen,
          message : this.props.snackBarInformation?.message,
          color : this.props.snackBarInformation?.color
        }
        })
      }
    }
  }

  closeSnackBar(){
    this.setState({
      snackBarState : {
        ...this.state.snackBarState,
        isOpen : false
      }
    })
  }

  closeDialog(e){
    this.props.dialogInformation.dialogState.openDialog(e);
  }

  handleConfigDrawerOpen(){
    this.setState({
      configSidebarState : {
        ...this.configSidebarState,
        isOpen : true
      }
    })
  }

  handleConfigDrawerClose(){
    this.setState({
      configSidebarState : {
        ...this.configSidebarState,
        isOpen : false
      }
    })
  }

  openDialog(){
    this.setState({
      snackBarState : {
        ...this.state.dialogProperties,
        isOpen : true
      }
    })
  }

  handleDrawerOpen(){
    this.setState({
      drawerProperties :{
        ...this.drawerProperties,
        isOpen : true
      }
    })
  }

  handleDrawerClose(){
    this.setState({
      drawerProperties :{
        ...this.drawerProperties,
        isOpen : false
      }
    })
  }

  render () {
    let dialogInformation = DialogInfo(this.state.dialogProperties);
    
    let location = null;
    
    if(window.location.pathname.replace('/','') !== "" && window.location.pathname.replace('/','') !== "dashboard"){
     location = "put";
    }
    
    let crumbs = [
      {
        title: 'Accueil',
        link: 'dashboard',
      }
    ]
  
    if(location){
      crumbs.push({
        title : this.props.frameConfigs?.title,
        link : window.location.pathname.replace('/','')
      })
    }

    const frameElement = this.props.frameConfigs;

    frameElement.openDialog = dialogInformation?.dialogState?.openDialog; 
    
    if(frameElement?.hideBar){
      return <Fragment>
                {renderBuilder(frameElement)}
                  <DialogBuilder theme={this.props.theme} 
                    dialogProperties= {{
                      openDialog : dialogInformation?.dialogState?.isOpen,
                      closeDialog : dialogInformation?.dialogState?.closeDialog,
                      dialogContent : dialogInformation?.dialogContent
                    }}
                  />
      </Fragment> 
            
    }

    return (
      <Fragment>
        <Box sx={{display: 'flex'}}>
          <CssBaseline />
           {
              frameElement?.hideSidebar ?  null : <Sidebar
                                                    name={frameElement?.name} 
                                                    openDialog={dialogInformation.dialogState.openDialog} 
                                                    handleDrawerClose={this.handleDrawerClose} 
                                                    open={this.state.drawerProperties.isOpen}
                                                    handleDrawerOpen={this.handleDrawerOpen} 
                                                  />
           } 
           
          <Box component="main" sx={{flexGrow: 1, p: 3}}>
            <div className={'bg-light app-content p-3 mt-3'}> 
          {
              frameElement?.hidePageTitle ?  null :
                <PageTitle
                  pageTitle={this.props.frameConfigs?.title}
                  pageIcon="Home"
                  breadCrumbsOptions={crumbs}
                />
          }
              <main className="mt-1">
                {renderBuilder(frameElement)}
              </main>
            </div>
          </Box>
          
          <DialogBuilder theme={this.props.theme} 
                        dialogProperties= {{
                          openDialog : dialogInformation?.dialogState?.isOpen,
                          closeDialog : dialogInformation?.dialogState?.closeDialog,
                          dialogContent : dialogInformation?.dialogContent
                        }}
                  />
            {
              this.state.configSidebarState.isOpen ? 
            <ConfigDrawer 
              handleDrawerClose={this.handleConfigDrawerClose} 
              open={this.state.configSidebarState.isOpen}/>
            :<></>
            }        
          <SnackbarBuilder snackProperties={this.state.snackBarState}/>
        </Box>
          {
           // this.props.authorizations?.includes("users:config") ?
            <Fab 
              sx={fabStyle} 
              aria-label={"Configuration"} 
              color={'warning'}
              onClick={this.handleConfigDrawerOpen}
            >
            <AddIcon/>
           </Fab>     //: null
          }  
         
      </Fragment>
    );
  }
}



function  renderBuilder(options){
  const Render = options?.render;
  if(Render){
    return <Render renderFrameConfigs={options}/>
  }
  return <FrameBuilder openDialog={options.openDialog} configurations={options}/>
}
