import CustomizedBreadcrumbs from '../breadcrumb';
import './pageTitle.css';

export default function PageTitle (props) {
  return (
    <div className="page-title">
      <CustomizedBreadcrumbs options={props.breadCrumbsOptions} />
    </div>
  );
}
