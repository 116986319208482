import {BrowserRouter as Router} from 'react-router-dom';
import AppRouter from '../app-router';
import {Provider} from 'react-redux';
import stores from '../global-state';

export default function Bootstrap (props) {
  return (
    <Provider store={stores}>
      <Router>
        <AppRouter />
      </Router>
    </Provider>
  );
}
