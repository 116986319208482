import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class AffiliationsQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'affiliations',
      path: 'affiliations',
    });
  }
}
