/**
 * Check and store the current connected user by  
 * @returns 
 */

const user = localStorage.getItem ('user');
const initialState = user ? JSON.parse (user) : null; //Get current connected user

const userReducer = (state = initialState, action = null) => {
  const {payload, type} = action;
  switch (type) {
    case 'login successful':
      state = {
        ...state,
        user: payload,
        isLoggedIn: true,
      };
      break;
    case 'logout successful':
      state = {
        ...state,
        user: null,
        isLoggedIn: false,
      };
      break;
    default:
      break;
  }
  return state;
};

export default userReducer;
