import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const transactionsConfiguration = {
  name: 'transactions',
  title: 'Transaction(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'dateTransaction',
      title: 'Date',
      type: 'datetime',
    },
    {
      name: 'senderName',
      title: 'Envoyeur',
    },
    {
      name: 'recipientName',
      title: 'Receveur',
    },
    {
      name: 'sentAmount',
      title: 'Montant envoyé',
      type: 'money',
    },
    {
      name: 'fee',
      type: 'money',
      title: 'Frais',
    },
    {
      name: 'total',
      type: 'money',
    },
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (transactionsConfiguration);
