import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const clientsConfiguration = {
  name: 'clients',
  title: 'client(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'Nom',
      col: '12',
    },
    {
      name: 'tel',
      title: 'Téléphone',
      col: '4',
      type: 'phone',
    },
    {
      name: 'email',
      title: 'Email',
      col: 4,
    },
    {
      name: 'address',
      title: 'Adresse',
      col: 4,
    },
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (clientsConfiguration);
