/**
 * This component will be use for build apps routes 
 */
import React, {Fragment} from 'react';
import routes from './routes.json';
import {Navigate, Route} from 'react-router-dom';
import NotfoundComponent from '../app-features/commons/Notfound';
import {capitalizeFirstLetter} from './../bootstrap/Helpers/utils';
import routeGuards from '../bootstrap/Helpers/route-guards';
import { useSelector } from 'react-redux';

function findComponentByRouteName (name, target = null) {
  if (name === '') {
    name = 'home';
  }

  if (!name) {
    throw new Error ('No name provided');
  }
  let defaultTarget = target || 'commons';
  const componentName = capitalizeFirstLetter (name).trim ();
  try{
      const component = require (`../app-features/${defaultTarget}/${componentName}`)
      .default;
      return component;
  }catch(exception){
    return NotfoundComponent; 
  }
}

function checkRedirection(key = null,redirectionPath = null){
  let redirect = redirectionPath;
  return <Route key={key} path={'*'} element={<Navigate to={redirect} />} />;
}

function transformToRoute (routeInformation,user = null) {

  let path = routeInformation.path
    ? routeInformation.path.toLowerCase ().trim ()
    : routeInformation.name.toLowerCase ().trim ();
  
  let component = routeInformation.component
    ? findComponentByRouteName (routeInformation.component, routeInformation.target)
    : findComponentByRouteName (routeInformation.name, routeInformation.target);
  
  
    /*if(routeInformation?.redirectTo){
      return checkRedirection(routeInformation.name.trim(),routeInformation?.redirectTo);
    }*/

    if (!component) {
        component = NotfoundComponent;
    }

    if(routeGuards (routeInformation?.protections, user)){
      return checkRedirection(routeInformation.name.trim(), routeGuards (routeInformation?.protections, user));
    }
  

  const RenderComponent = component;
  return (
    <Route
      key={routeInformation.name.toLowerCase ().trim ()}
      path={'/' + path}
      element={<RenderComponent />}
    />
  );
}

export default function RoutesBuilder (props) {
  const connectedUser =  useSelector(state => state.auth);
  return (
    <Fragment>
      {routes.map (item => transformToRoute (item,connectedUser))}
    </Fragment>
  );
}
