import { alpha, Box, Button, Menu, styled, Tab, Tabs, TextField } from "@mui/material";
import React,{ Fragment, useState } from "react";
import { capitalizeFirstLetter, getFixedDateInterval } from "../../../../../../../../bootstrap/Helpers/utils";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { predefinePeriods } from "../../../../../../../../app-consts/consts";




let dateTimeObj = {};

export function DateTimePicker(props){
    const [focus, setFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);
    
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
  
    const changeDateValue = e => {
      e.preventDefault();
      if(e.target.value){
        setHasValue(true);
      }else{
        setHasValue(false);
      }
      props.properties?.changeDate(e);
    }
    const label = props?.properties?.label || props?.properties?.name;
    return   <TextField
                label={capitalizeFirstLetter(label || "")}
                id={props?.properties?.id || props?.properties?.name}
                value={props?.properties?.value || ""}
                size={props?.properties?.size || "small"}
                onChange={changeDateValue}
                name={props?.properties?.name}
                sx={{ width : 200, mr:1 }}
                onFocus={onFocus}
                onBlur={onBlur}
                type={hasValue || focus ? "datetime-local" : "text"}
                InputProps={{ inputProps: { min: props.properties?.min, max: props.properties?.max } }}
                
            />
}

export default function PeriodPicker(props){
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return  <Fragment>
                <Button
                    id="pick-period-button"
                    aria-controls={open ? 'pick-period' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    size={props?.size || "small"}
                    onClick={handleClick}
                    endIcon={<FontAwesomeIcon icon={"circle-chevron-down"} />}
                    sx={{ mx : 1, px : 3 }}
                >
                    Periode
                </Button>
                <StyledMenu
                    id="pick-period"
                    MenuListProps={{
                        'aria-labelledby': 'pick-period',
                    }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                >
                     
                   <SelectPeriodComponent {...props}/>
                </StyledMenu>
            </Fragment>
}


const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
))(({ theme }) => ({
'& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
    theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
    'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
    padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
    '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
    },
    '&:active': {
        backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity,
        ),
    },
    },
},
}));


const SelectPeriodComponent = props => {
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);

    const [maxStartDate,setMaxStartDate] = useState(new Date());

    const [minEndDate,setMinEndDate] = useState(new Date());


    const changePeriod = (e,option) => {
        const newInterval = getFixedDateInterval(option,true);
        props.changeSearchValue(e,newInterval,props.data.name);
        updateDates(newInterval);
    }

    const updateDates = async (newDate) => {
        
        const dates = newDate.split(","); 

        const startDateTime = dates[0];


        await setStartDate(startDateTime)
        await setMinEndDate(startDateTime);
        dateTimeObj.start = dates[0];
        
        const endDateTime = dates[1];

        await setEndDate(endDateTime);
        await setMaxStartDate(endDateTime);
        dateTimeObj.end = dates[1];

    }

    const changeDate = async (e) => {
        if(e.target.name === "startDate"){
            await setStartDate(e.target.value)
            await setMinEndDate(e.target.value);
            dateTimeObj.start = e.target.value;
        }
        
        if(e.target.name === "endDate"){
            await setEndDate(e.target.value);
            await setMaxStartDate(e.target.value);
            dateTimeObj.end = e.target.value;
        }
        props.changeSearchValue(e,buildNewDateInterval(dateTimeObj.start,dateTimeObj.end),props.data.name);
    }

    const buildNewDateInterval = (start,end,separator = ",") => {
        let newDate = null;

        if(start && end){
        newDate = moment(start).format('YYYY-MM-DD HH:mm:ss') + separator+ moment(end).format('YYYY-MM-DD HH:mm:ss');
        }else{
        if(start && !end){
            newDate = moment(start).format('YYYY-MM-DD HH:mm:ss') + separator+ moment(start).format('YYYY-MM-DD HH:mm:ss');
        }else if(!start && end) {
            newDate = moment(end).format('YYYY-MM-DD HH:mm:ss') + separator+ moment(end).format('YYYY-MM-DD HH:mm:ss');      
        }
        }
        
        return newDate;
    }
  
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    
    return (
      <Box sx={{ width: '100%'}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Périodes prédefinies" {...a11yProps(0)} />
            <Tab label="Préciser périodes" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <RadioButtonsGroup changePeriod={changePeriod} predefinePeriods={predefinePeriods} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
         <DateRangePickerValue
            size={props.size}
            startDate={startDate} 
            endDate={endDate}
            maxStartDate={maxStartDate}
            minEndDate={minEndDate}
            changeDate={changeDate}
         />
        </CustomTabPanel>
      </Box>
    )
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
}


CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function DateRangePickerValue(props){
    return <Fragment>
                <DateTimePicker
                    properties={
                    {
                        label:"Date debut",
                        id:"startDate",
                        value:props.startDate || null,
                        size: props.size || "small",
                        changeDate:props.changeDate,
                        name:"startDate",
                        max:props.maxStartDate  
                    } 
                    }
                />
                <DateTimePicker
                    properties={
                    {
                        label:"Date fin",
                        id:"endDate",
                        value: props.endDate || null,
                        size: props.size || "small",
                        changeDate: props.changeDate,
                        name:"endDate",
                        min:props.minEndDate   
                    } 
                    }
                />
            </Fragment>

}


function RadioButtonsGroup(props) {

  const periods = props?.predefinePeriods ||  [];

  return (
    <FormControl>
      <FormLabel id="period">Selectionnez une période</FormLabel>
      <RadioGroup
        aria-labelledby="period"
        defaultValue={null}
        name="radio-buttons-group"
        onChange={(e) => { props.changePeriod(e,e.target.value)}}
      >
        {
            periods.map(
                p => {
                    return <FormControlLabel  key={p.value} value={p.value} control={<Radio />} label={p.label} />
                }
            )
        }
        
      </RadioGroup>
    </FormControl>
  );
}