import BaseBackComponent from '../base-back-component';

export default class AffiliationsComponent extends BaseBackComponent {
  name = 'Affiliations';

  options = [
    {
      title: 'clients',
      keys: ['Nom'],
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    return data;
  }
}
