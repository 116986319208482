import BaseBackComponent from '../base-back-component';

export default class DashboardComponent extends BaseBackComponent {
  name = 'Dashboard';

  state = {
    ...this.state,
    target: 'back',
  };

  componentDidMount () {
    this.initDataLoading ();
  }
}
