import {IconButton} from '@mui/material';
import AppBar from './app-bar';
import MenuIcon from '@mui/icons-material/Menu';
import './app-bar.css';
import {Fragment} from 'react';

const Header = props => {
  if (!props.open) {
    return (
      <AppBar position="fixed" open={props.open}>
        <div className="app-bar">
          <div className="d-flex justify-content-start p-2 mx-3">
            <IconButton
              color="blue"
              aria-label="open drawer"
              onClick={props.handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(props.open && {display: 'none'}),
              }}
            >
              <MenuIcon />
            </IconButton>
          </div>

        </div>

      </AppBar>
    );
  }
  return <Fragment />;
};

export default Header;
