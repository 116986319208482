import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import './frame-title.css';
import {Button, ButtonGroup} from '@mui/material';
import {Link} from 'react-router-dom';
import {Fragment} from 'react';
import { useSelector } from 'react-redux';

export default function FrameTitle (props) {
  const actions = props.actions || [];
  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position="static">
        <Toolbar className="title">
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            {props.title.toUpperCase ()}
          </Typography>
          <div>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
            >
              <HeaderActionRender 
                featureName={props.name} 
                openDialog={props.openDialog} 
                actions={actions} 
              />
            </ButtonGroup>

          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const HeaderActionRender = props => {
 // const nameFeature = props.featureName;
 // const currentUser = useSelector(state => state.auth);

  return (
    <Fragment>
      {props.actions?.map (action => {
       // let renderTest = currentUser?.user?.authorities?.includes(nameFeature+":"+action.name);

       // if(renderTest){
          return action.actionTarget === 'link'
          ? <Link 
                className="mx-1 text-white border-white" 
                key={action.name} to={"/"+action.href.toLowerCase() ?? props.featureName.toLowerCase()+"/"+action.name.toLowerCase()}
            >{action.title}</Link>
          : 
            <Button
              key={action.name}
              variant="outlined"
              className="mx-1 text-white border-white"
              id={action.name}
              name={action.name}
              onClick={(e) => {props.openDialog(e,action?.name)}}
            >{action.title}</Button>;
      //  }
        //  return null;
      })}
    </Fragment>
  );
};
