function buildHeader(headerProperties){
    return {
        name : headerProperties?.name,
        title : headerProperties?.title ?? headerProperties?.name,
        type : headerProperties?.type ?? "text",
        options : headerProperties?.options ?? null,
        fields: headerProperties?.fieldsSelected ?? ['id'],
    }
}

export default buildHeader;